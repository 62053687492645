.webcrossing-widget-category-boxlist{
  @include outerContent(nopadding);
  ul{
    @include flex(flex-start, $wrap: true);
    gap: 3rem;
    li{
      width: calc(25% - 2.3rem);
      text-align: center;
      position: relative;
      overflow: hidden;
      @include transition;
      > a{
        @include overlay(0,0);
        z-index: 1;
      }
      h2{
        text-align: center;
        @include fontDefault(2.8, 3rem);
        @include fontAdditional($colorWhite, 600);
      }
      &:hover{
        .actions{
          max-height: 22rem;
          opacity: 1;
        }
        .image{
          img{
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .box-wrapper{
    @include overlay;
    padding:0 7rem;
    @include flexAlign(center,center);
    overflow: hidden;
  }
  .action{
    @include buttonWhite;
    background: transparent;
    text-transform: uppercase;
    margin-top: 2rem;
    & + .action{
      margin-left: 0;
    }
    &s{
      max-height: 0;
      width: auto;
      overflow: hidden;
      opacity: 0;
      @include transition($duration: .5s);
      @include flexAlign(null, center);
      @include flexDirection(column, flex);
    }
  }
  .overlay{
    z-index: 1;
    @include overlay(50%, 50%, auto, auto);
    transform: translate(-50%, -35%);
    max-width: 90%;
    @include transition;
    width: 100%;
  }
  .image{
    width: auto;
    z-index: 0;
    position: relative;
    line-height: 0;
    img{
      @include transition;
      transform: scale(1);
      &.mobile{
        display: none;
      }
      @include breakpoint(size768px){
        &.mobile{
          display: block;
        }
        &.desktop{
          display: none;
          &:only-child {
            display: block;
          }
        }
      }
    }
    &:before{
      content: "";
      @include overlay;
      background: rgba($colorPrimary, .5);
      z-index: 1;
    }
  }
  .hidden{
    max-height: 0;
    overflow: hidden;
    @include transition;
    color: $colorWhite;
    a{
      color: $colorWhite;
      text-decoration: underline;
      font-weight: bold;
    }
    > a{
      font-size: 2rem;
      line-height: 2.4rem;
    }
    .text{
      font-weight: 300;
      line-height: 2.2rem;
      font-size: 1.8rem;
      margin-bottom: 2rem;
      a{
        text-decoration: none;
      }
    }
  }
  &.alternative{
    .overlay{
      transform: translate(-50%, -50%);

    }
    ul{
      li{
        h2{
          @include transition;
          overflow: hidden;
          max-height: 20rem;
          opacity: 1;
        }
        &:hover{
          h2:not(:only-child){
            max-height: 0;
            opacity: 0;
          }
        }
      }
    }
  }
  .block-title{
    padding: {
      right: 2rem;
      left: 2rem;
    };
  }
  /*** RESPONSIVE ***/
  @include breakpoint(size1024px){
    .image{
      width: 100%;
      img{
        min-width: 100%;
      }
    }
    ul{
      li{
       width: calc(50% - 1.5rem);
        @include flex;
        align-items: flex-start;
        padding-top: 0;
      }
    }
  }/* End 1024px */
  @include breakpoint(size768px){
    &.alternative{
      ul{
        li{
          &:hover{
            .actions{
              @include flex;
            }
            h2{
              max-height: initial;
              opacity: 1;
            }
          }
        }
      }
    }
    ul {
      gap: .4rem;
      li {
        width: calc(50% - .2rem);
        &:hover{
          .actions{
            display: none;
          }
          > a{
            z-index: 2;
          }
          h2{
            max-height: initial;
            opacity: 1;
          }
        }
        h2{
          @include fontDefault(2, normal);
        }
      }
    }
  }/* End 768px */
}