.footer{
  &-advantages, &-main, &-bottom, &-payment{
    background: $colorPrimary;
    @include border(1px, $colorDarkGrey, $position: bottom);
    padding: {
      top: 6rem;
      bottom:6rem;

    };
  }
  &-flowing-text, &-copyright{
    background: $colorGrey;
  }
  &-advantages{
    ul{
      @include flexAlign(space-between, center, flex);
      width: 100%;
      li{
        @include flexAlign(flex-start, center, flex);
        @include fontAdditional($colorWhite, 500, uppercase);
        &:before{
          content:url($iconDir + "listtype_w.svg");
          margin-right: 2rem;
        }
      }
    }
  }
  &-main{
    @include fontAdditional($colorWhite);

    .section{
      width: 23%;
      &.footer-main-shop{
        width: 55%;
      }
      a{
        @include flexAlign(flex-start, center, flex);
        width: 100%;
        @include fontAdditional($colorWhite);
      }
    }
    &-contact{
      li{
        line-height: 3rem;
      }
      a{
        margin-top: 3rem;
        &:before{
          content:url($iconDir + "pin.svg");
          margin-right: 2rem;
        }
      }
    }
    &-help{
      li{
        @include flexAlign(flex-start, center, flex);
        padding-bottom: 3rem;
        &:first-child{
          padding-bottom: 0;
        }
        i{
          @include fontDefault(3, normal);
          @include fontAdditional($weight: 300);
        }
        a{
          margin-left: 2.8rem;

        }
        &:last-child{
          padding-bottom: 0;
          &:before{
            content:url($iconDir + "360_degrees.svg");
          }
        }
      }
    }
    &-shop{
      @include flexAlign(flex-start, flex-start, flex);
      ul{
        width: 50%;
        padding-right: 3.6rem;
      }
      li{
        line-height: 3rem;
      }
    }
  }
  &-payment{
    ul{
      @include flex(space-between, center, $gap: 2rem);
      li{
        @include flex(space-between, center, $gap: 2rem);
        &:last-child{
          white-space: nowrap;
        }
      }
    }
    .inner-container{
      > ul{
        width: 100%;

        color: $colorWhite;
        > li{
          @include fontAdditional(null, 500, uppercase);
          &:before{
            display: inline-block;
            vertical-align: middle;
            margin-right: 2rem;
          }
          &:first-child{
            &:before{
              content:url($iconDir + "sichere_bezahlung.svg");
            }
          }
          &:nth-of-type(2){
            &:before{
              content:url($iconDir + "ssl.svg");
            }
          }
        }
      }
    }
  }
  &-bottom{
    &-info{
      @include fontAdditional($colorWhite);
      max-width: 50%;
      ul{
        @include flex($wrap:true);
      }
      li{
        @include flex(flex-start, center);
        width: 50%;
        &:first-child{
          width: 100%;
          &:before{
            display: none;
          }
        }
        &:before{
          @include fontIcon(1);
          content: $iconArrow;
          transform: rotate(90deg);
          margin-right: 1.65rem;
        }
      }
      a{
        @include fontAdditional($colorWhite);
      }
    }
    &-socialmedia{
      max-width: 40%;
    }
  }
  &-copyright{
    @include border(1px, rgba($colorDarkGrey, .2), $position: top);
    ul{
      text-align: center;
      padding: 1.7rem 0;
      @include fontDefault(1.6, 2.5rem);
    }
  }
  &-flowing-text{
    padding: {
      top: 3.3rem;
      bottom: 3.3rem;
    };
  }
}
footer{
  .inner-container, .webcrossing-widget-teaser-text:not(.newsletter) .block-title{
    @include flexAlign(space-between, flex-start, flex);
    @include outerContent;
    h2{
      @include fontAdditional($colorWhite, 600);
      @include fontDefault(2.4, 3.5rem);
      margin-bottom: 3rem;
    }
  }
}
