.checkout {
  &-cart, &-index {
    &-index {
      .title {
        @include fontDefault(2, 1, $colorDarkGrey2, $weight: bold);
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        display: block;
        @include border($color: $colorDarkGrey2, $position: bottom);
      }
    }
  }
}
.cart {
  &-empty{
    a{
      color: $colorSecondary;
    }
  }
  &-price {
    @include fontDefault(1.6, 2rem, $weight:  bold);
    letter-spacing: 0;
  }
  &-sidebar{
    margin-top: 2.8rem;
  }
  &-membership{
    padding: 2.1rem 3rem;
    background: rgba($colorPrimary, 0.15);
    position: relative;
    margin-top: 5rem;
    @include fontDefault(1.6, null);
    h3{
      text-align: left;
      @include fontDefault(2, null);
    }

    &:after{
      @include overlay(0, 0, 8rem, 9rem, $right: true, $bottom: true);
      content: "";
      background-size: 12rem 12rem;
    }
  }
  &-container {
    @include flex(space-between, flex-start, row-reverse, true);
    padding-top: 4rem;
    #gift-options-cart {
      display: none;
    }
    > form {
      width: calc(100% - 36rem) !important;

      .actions {
        padding-top: 2rem;
        @include flex(space-between, center);
        &-toolbar {
          @include flex(null, center);
          .towishlist span {
            display: none;
          }
        }
        &-edit {
          display: none;
        }
      }
    }

    caption.table-caption {
      display: none;
    }
  }
  &-summary {
    width: 33rem;
    .title{
    }
    .block.shipping {
      display: none;
    }
    .summary {
    }
    .checkout{
      &.methods{

        li{
          width: 100%;
          margin-right: 0;
          @include fontDefault(1.6, 2rem, $colorFontDefault, normal);
          &:first-child{
            margin-bottom: 2rem;
          }
        }
      }
      .action{
        margin: {
          top: 0;
          right: 0;
        }
      }
    }
    .primary {
      width: 100%;
    }
    .block {
      &.discount {
        cursor: pointer;
        color: $colorPrimary;
        margin-top: 1.5rem;
        .title{
          display: none;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
          @include fontDefault(1.6, 2.6rem, null, normal);
          strong{
            font-weight: normal;
          }
        }
        .field {
          margin-bottom: 1rem;
        }
        .fieldset.coupon {
          label {
            display: none;
          }
        }
        input{
        }
        .actions-toolbar {
          width: 100%;
          position: relative;
          left: inherit;
          top: inherit;
        }
      }
    }
  }

  &-totals {

    .table {
      &-caption {
        text-align: right;
      }
      &-wrapper {
        position: relative;
        tr.grand.totals {
          color: black;
        }
      }
    }
  }
}

/*** RESPONSIVE ***/

@include breakpoint(size1500px){
  .cart {
    &-summary{
      #block-discount{
        margin-left: 0;
        width: 100%;
      }
    }
    &-container {
      form {
        width: 100%;
        &.form-cart {

        }
      }
    }
  }
  #checkout-shipping-method-load{
    padding: 1rem 2rem;
  }
} /* END 1500px */
@include breakpoint(size1024px){
  .cart {
    &-container{
      padding-top: 7rem;
      > form{
        .actions{
          @include flex($wrap: true);
        }
      }
    }
    &-membership{
      width: 49%;
      margin: {
        top:0;
        left: 2%;
      }
    }
    &-summary{
      order: 0;

      .summary{
        &.title{
          padding-bottom: 3rem;
        }
      }
    }
  }
}/* END 1024px */
@include breakpoint(size768px){
  .cart{
    &-sidebar{
      @include flex($wrap:true);
    }
    &-summary{
      width: 100%;
      margin-top: 7rem;
    }
    &-container {
      @include flex($direction: column-reverse);

      form.form-cart{
        width: 100% !important;
        order: 0;
      }
    }
    &-membership, &-summary{
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
    &-membership{
      margin-top: 5rem;
    }
    &-container{
      form {
        .actions {
          @include flex($wrap:true);
          padding-top: 2rem;
          > .action{
            margin: {
              left:0;
              bottom: 1rem;
            }
            line-height: normal;
            width: 100%;
          }
        }
      }
    }
  }
}/* END 768px */