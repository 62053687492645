@media not all and (min-resolution:.001dpcm) {
    .wishlist-index-index .actions {
        &-toolbar, .toolbar {
            .primary{
                .action{
                    margin-right: 1rem;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .account, .customer-account-create {
        .customer-container {
            form {
                fieldset {
                    margin-right: 3rem;
                    &:nth-of-type(2n){
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .account .customer-container {
        .block, .toolbar{
            margin-top: 3rem;
        }
        .block {
            &:first-child{
                margin-top: 0;
            }
            &-content .box {
                margin: {
                    right: 3rem;
                    bottom: 3rem;
                }

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }

    .checkout-agreement input{
        margin-top: .5rem;
    }
    .grid .product-item{
        margin-right: 2rem;
        &:nth-of-type(4n){
            margin-right: 0;
        }
    }
    .customer-container{
        .block{
            margin-right: 3rem;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .product-item{
        margin-right: 3rem;
        &:nth-of-type(4n){
            margin-right: 0;
        }
    }
    .pages ul li{
        margin-right: .3rem;
        &:last-child{
            margin-right: 0;
        }
    }
    .swatch-option {
        margin: {
            right: 1rem;
            bottom: 1rem;
        }
        &.image{
            margin: {
                right: .2rem;
                bottom: .2rem;
            }
        }


    }
    .payment-method-title, .billing-address-same-as-shipping-block{
            @include flex(null, center);

    }
    .webcrossing-widget {
        &-category-boxlist{
            ul {
                li {
                    margin: {
                        right: 3rem;
                        bottom: 3rem;
                    }

                    &:nth-of-type(4n) {
                        margin-right: 0;
                    }
                }
            }
        }
        &-teaser-box{
            .box{
                margin: {
                    right: 3rem;
                    bottom: 3rem;
                }
                &:nth-of-type(4n) {
                    margin-right: 0;
                }
            }
        }
        &-teaser-text {
            &.flowing-text{
                .description-additional{
                    > div{
                        margin-right: 3rem;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
            &.social-media .description-additional ul {
                li {
                    margin: {
                        right: 1.5rem;
                    }
                }
            }
        }
        &-category-tab .categories .sub-category{
            margin: {
                right: 3rem;
            }

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }
    }
    .footer-payment ul li ul li{
       margin-left: 2rem;
    }
    .navigation ul {
        li{
            &.menu-cms-block{
                margin-bottom: 2rem;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .nav {
            &-4 li.menu-activities ul li {
                margin: {
                    right: 3rem;
                    bottom: 3rem;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            &-5 > ul > li ul li {
                margin-right: 8rem;

                &:nth-of-type(6n) {
                    margin-right: 0;
                }
            }
        }
    }
    .contact .fieldset > div{
        margin-right: 2rem;
        margin-top: 2rem;
        &:nth-of-type(2n){
            margin-right: 0;
        }
    }
    @supports (-webkit-appearance:none) {

        @include breakpoint(size1200px){
            .product-item{
                &:nth-of-type(4n){
                    margin-right: 3rem;
                }
                &:nth-of-type(3n){
                    margin-right: 0;
                }
            }
        }
        @include breakpoint(size1024px){
            .webcrossing-widget {
                &-category {
                    &-tab .categories h2{
                        padding: 0 1.2rem 1.2rem;
                    }
                    &-boxlist {
                        ul {
                            li {
                                &:nth-of-type(2n) {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
                &-teaser-box {
                    .box {
                        margin: {
                            right: 0;
                            bottom: 0;
                        }
                    }
                    &.alternative{
                        .box {
                            margin: {
                                right: .4rem;
                                bottom:.4rem;
                            }
                            &:nth-of-type(2n){
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .product-item{
                margin-right: 0;
                &:nth-of-type(3n){
                    margin-right: 0;
                }
                &:nth-of-type(2n){
                    margin-right: 0;
                }
            }
            .footer-payment ul li {
                &:last-child{
                    margin-top: 1rem;
                }
            }
        }
        @include breakpoint(size768px){
            .webcrossing-widget {
                &-category-boxlist {
                    ul {
                        li {
                            margin: {
                                right: .4rem;
                                bottom: .4rem;
                            }
                        }
                    }
                }
                &-category-tab .categories .sub-category{
                    margin: {
                        right: .5rem;
                    }
                }
            }
            .footer-payment ul li {
                ul li {

                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
        }
        @include breakpoint(size480px){
            .product-item{
                margin-right: 0;
                &:nth-of-type(3n){
                    margin-right: 0;
                }
                &:nth-of-type(2n){
                    margin-right: 0;
                }
            }
        }
        @include breakpoint(size360px){
            .product-item{
                &:nth-of-type(3n),&:nth-of-type(4n){
                    margin-right: 0;
                }
            }
        }
    }
}