.block-search{
  @include flex;
  flex-direction: row-reverse;
  .account &{
    .block-title{
      padding: 0;
      margin: 0;
      border: 0;
    }
  }
  .block{
    &-title {
      padding: 0;
      margin: 0;
      border: 0;
      cursor: pointer;
      height: 2.8rem;
      &:before {
        content: url($iconDir + "suche.svg");
      }
      strong {
        display: none;
      }
    }
    &-content {
      @include overlay(-100%, 0, 100%, auto);
      overflow: hidden;
      @include transition;
      padding: 3.3rem 0;
      z-index: -1;
      .action{
        &.search {
          padding: 0;
          border: 0;
          margin-right: 0;
          display: block;
          background: transparent;
          &:before {
            @include fontIcon;
            margin-right: 2rem;
            content: url($iconDir + "suche.svg");
            display: block;
          }
          span {
            display: none;
          }
        }
      }
      form{
        @include outerContent;
        @include flexAlign(center, center, flex);
      }
      .field.search {
        width: 100%;
      }
      input {
        border: none;
        padding: 0;
        background: transparent;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-size: 1.8rem;
          line-height: 3.8rem;
          color: $colorPrimary;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          font-size: 1.8rem;


          line-height: 3.8rem;

          color: $colorBlack;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          font-size: 1.8rem;
          line-height: 3.8rem;

          color: $colorBlack;
        }
        &:-moz-placeholder { /* Firefox 18- */
          font-size: 1.8rem;
          line-height: 3.8rem;
          color: $colorBlack;
        }
      }

      label {
        display: none;
      }
    }
  }
  &.active {
    .block {
      &-title {
        &:before{
          @include fontIcon(3.3);
          content: $iconClose;
          line-height: normal;
        }
      }
      &-content {
        @include overlay(100%, null, null, null);
        background: $colorGrey;
        z-index: 0;
        opacity: 1;
        .form{
          @include border($position: bottom);
        }

      }
    }
  }
  @include breakpoint(size1024px){
    .bottom &{
      width: 100%;
      .block{
        &-content{
          padding: 1.5rem;
          background: $colorWhite;
          form{
            @include border($color: $colorSwatchGrey);
          }
          .action.search::before{
            margin-right: 0;
          }
        }
      }
    }
  }
}