.webcrossing-widget-teaser-ticker{
  background: $colorSecondary;
  padding: 1.6rem 0;
  margin-top: 1rem;
  .block-content{
    @include outerContent;
    color: $colorWhite;
    @include flexAlign(center, center, flex);
    text-align: center;
  }
  a{
     @include fontAdditional($colorWhite, null, null, underline);
    margin-left: 1rem;
  }
}