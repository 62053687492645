/*Modal*/
body._has-modal {
  height: 100%;
  width: 100%;
  .header-main{
    z-index: 1;
  }
}
#opc-new-shipping-address{
  width: 100%;
  > form{
    padding-right: 2rem;
  }
}
.modal {
  &s-wrapper{
    .modal{
      &-inner-wrap{
        background: $colorWhite;
        max-height: 90vh;
        @include outerContent;
        width: auto;
        padding: 3.2rem;
        max-width: 90%;
      }
      &-content{
        overflow-y: auto;
        max-height: calc(90vh - 30rem);
        h2{
          @include fontDefault(5.3, 6.3rem);
          @include fontAdditional($weight: 600);
        }
        h3{
          @include fontDefault(2.7, 3.2rem);
          @include fontAdditional($weight: 600);
          margin: {
            top: 3.5rem;
            bottom: 2rem;
          }
        }
      }
    }
  }
  &-popup{
    @include overlay($position: fixed);
    display: none;
    background: rgba($colorBlack, .7);
    &._show{
      @include flexAlign(center, center, flex);
      z-index: 2;
    }
  }
  &-header{
    position: relative;
    min-height: 5rem;
    .action-close{
      @include overlay(0, right, null, null);
      cursor: pointer;
      @include fontDefault(0);
      font-size: 0;
      border: 0;
      background: transparent;;
      &:before{
        content: $iconClose;
        display: inline-block;
        text-align: center;
        @include fontAdditional($colorPrimary);
        @include fontIcon(2, normal);
        border-radius: if($rounded-corners, 50px, null);
        height: 2.3rem;
        width: 2.3rem;
        @include flexAlign(center, center);
        margin: 0 auto;
      }
    }
  }
  &-footer{
    @include flex;
    margin-top:2rem;
  }
} /* Modal */
.fieldset[disabled]{
  .modal{
    &-costum, &-popup, &-slide{
      .action-close{
        cursor: not-allowed;
        opacity: .5;
        pointer-events: none;
      }
    }
  }
}
.webcrossing-popup-1{
  .modal {
    &-inner-wrap {
      padding: 0;
    }
    &-header{
      @include overlay(3.7rem, 3.7rem, null, null, $right: true);
    }
    &-footer{
      display: none;
    }
    &-content{
      .newsletterPopup{
        @include flex;
        .image{
          background: no-repeat center center;
          background-size: cover;
          max-width: 50%;
          width: 100%;
          position: relative;
          line-height: 0;
          .new{
            @include overlay(2rem, left);
            text-transform: uppercase;
            color: $colorWhite;
            background: $colorPrimary;
            padding: 1.3rem {
              left: 1.5rem;
              right: 1.7rem;
            }
            @include fontDefault(2.1, 2.1rem);
            @include fontAdditional($weight: bold);
            font-style: italic;
          }
          img{
            opacity: 0;
            width: 100%;
            height: 100%;
          }
        }
        .text{
          width: 50%;
          @include flexAlign(center, center, flex);
          padding: 3.7rem;
          &Container{
            h2{
              text-align: left;
              @include fontDefault(4, null);
              @include fontAdditional($colorPrimary, null, normal);
              padding-top: 0;
            }
            .note{
              padding-top: 7rem;
              font-weight: 700;
              span{
                font-size: 3rem;
                color: $colorRed;
              }
            }
            .inputfield{
              .title{
                display: none;
              }
              .content{
                .label{
                  display: none;
                }
              }
              form{
                @include flex;
                padding-top: 1rem;
              }
              input{
                width: 100%;
              }
              button{
                width: 10.6rem;
                height: 4.6rem;
                background: $colorPrimary;
                color: $colorWhite;
                border-color: $colorPrimary;
                padding: 0;
                border-radius: 0;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
.ie10 .modal-popup._inner-scroll, .ie9 .modal-popup._inner-scroll {
  overflow-y: auto;
}
.ie10 .modal-popup._inner-scroll .modal-inner-wrap, .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
  max-height: none;
}
div#newsletter-error {
  padding: 10px;
}
body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 899;
}
/*Modal Ende*/

.mp {
  &_modal{

    position: fixed;
    width: 100%;
    background: rgba($colorSecondary, .8);
    height: 100%;
    z-index: 9999;
    display: none;

  }
  &_popupquote {
    &-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 80rem;
      max-height: 90vh;

      background: $colorWhite;
      padding: 5rem 3rem;
      width: 85%;
      .close{
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        font-size: 0;
        &:before{
        }
      }
      .actions-toolbar{
        margin-top: 2rem;
      }
      > form{
        overflow-y: auto;
        max-height: calc(90vh - 10rem);
      }
      .terms_condition{
        @include flex;
        input{
          width: auto;
          margin-right: 1rem;
        }
        .label{
          width: 100%;
          &:after{
            position: relative;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
}
@include breakpoint(size768px){
  .modals-wrapper .modal-inner-wrap{
    width: 95%;
    padding: 3.2rem 2rem;
    max-height: 95vh;
  }
}

@include breakpoint(size480px){
  .modal{
    &-footer{
      @include flex($wrap:true);
    }
  }
}
