.webcrossing-widget-teaser-box{
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  &.default{

  }
  &.alternative{
    .box{
      border:0;
      padding: 0;
      &:hover{
        .overlay{
          background: rgba($colorPrimary, .8);
        }
      }
      .image{
        margin-bottom: 0;
        line-height: 0;
      }
    }
    .overlay{
      background: rgba($colorPrimary, .5);
      h2{


        color: $colorWhite;
      }
    }
    .block {
      &-title{
        padding-top: 5rem;
      }
      &-content {
        @include outerContent(nopadding);
      }
    }
  }
  .block{
    &-title{
      position: relative;
      z-index: 1;
      padding: {
        right: 2rem;
        left: 2rem;
      };
    }
    &-content{
      position: relative;
      z-index: 1;
      @include outerContent;
    }
  }
  .action {
    margin-top: 5rem;
    &s {
      text-align: center;
    }
  }
  .box{
    @include flexDirection(column, flex);
    width: calc(25% - 2.3rem);
    overflow: hidden;
    padding: 4.7rem 0 0;
    @include border(1px, $colorSwatchGrey);
    position: relative;
    @include transition;
    &:hover{
      .overlay{
        background: $colorSecondary;
        h2{
          padding-top: 0;
          margin-top: 0;
          margin-bottom: 2rem;
          color: $colorWhite;
        }
      }
      .description{
        max-height: 20rem;
      }
    }
    .image{
      text-align: center;
      overflow: hidden;
      margin-bottom: 11.9rem;
    }
    &es{
      @include flex;
      gap: 3rem;
    }
  }
  .overlay{
    @include overlay;
    @include flex(centery, $wrap: true);

    @include transition;
    h2{
      @include fontAdditional(default, 600);
      margin-top: 4rem;
      text-align: center;
      @include transition;
    }
  }
  .description{
    text-align: center;
    overflow: hidden;
    @include transition;
    max-height: 0;
    padding:0 2rem;
    @include fontDefault(1.8rem);
    @include fontAdditional($colorWhite, normal);
    p{

      line-height: 3rem;
    }
    a{
      display: inline-block;
      width: 100%;
      text-align: center;
      @include fontAdditional($colorWhite, $style: underline);
    }

  }
  /*** RESPONSIVE ***/
  @include breakpoint(size1024px){
    .overlay{
      h2{
        padding: 0 2rem;

      }
    }
    .box {
      width: 50%;
      @include border(1px, $colorSwatchGrey);

      &es {
        gap: 0;
        @include flex($wrap: true);
      }
    }
    &.alternative{
      .box {
        width: calc(50% - .2rem);
        &es {
          gap: .4rem;
        }
        img{
          min-width: 100%;
        }
      }
    }
  }
  @include breakpoint(size768px){
    .box{
      .overlay{
        h2{
          max-height: 5rem;
        }
        .description{
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: normal;
          a{
            overflow: hidden;
            opacity: 0;
            max-height: 0;
            @include transition;
            @include overlay(0, 0, 100%, auto, $bottom: true);
          }
        }
        &-content{
          position: relative;
          @include transition;
        }
      }
      &:hover{
        .overlay-content{
          padding-bottom: 3rem;
        }
        .description{
          a{
            opacity: 1;
            max-height: 2rem;

          }
        }
      }
    }
    &.alternative{
      .box {
        width: calc(50% - .2rem);
        &es{
          gap: .4rem
        }
        .overlay{
          h2{
            margin-top: 0;
            margin-bottom: 0;
          }
          .description{

          }
        }
        &:hover{
          .overlay{
            h2{
              margin-bottom: 0;
              max-height: 0;
              overflow: hidden;
              opacity: 0;
            }

          }
        }
      }
    }

  }/* End 768px */
  @include breakpoint(size480px){
    .block{
      &-content{
        .left{
          width: 100%;
          margin-bottom: 4rem;
        }
        .info{
          width: 100%;
        }
      }
    }
  }/* End 480px */
}