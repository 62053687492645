input, select, textarea {
  @include fontDefault($fontButton , 2.6rem);
  @include fontAdditional($colorPrimary, normal);
  @include border(2px, $colorInputBorder);
  width: 100%;
  padding: 1.1rem 1.7rem;
  background: $colorWhite;
  border-radius: if($rounded-corners, 5px, null);
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  &.checkbox{
    margin-right: .7rem;
    width: 1.3rem;
    height: 1.3rem;
    margin-top: 0.4rem;
    padding: 0;
  }
  &[type="number"]{
    -moz-appearance: textfield;
    -moz-box-sizing:    border-box;
    -webkit-box-sizing: border-box;
    box-sizing:        border-box;
  }
  &[type="radio"]{
    padding: 0;
    font-size: 1.4rem;
    -webkit-appearance: radio;
    line-height: normal;
  }
  &[type="checkbox"]{
    width: auto !important;
    font-size: 1.4rem;
    -webkit-appearance: checkbox;
  }
  &[readonly=""]{
    background: $colorGrey;
    color: rgba($colorSecondary, .5);
    cursor: not-allowed;
  }
}

select#country {
  min-height: 3.6rem;
}
fieldset {
  border: none;
  display: block;
  br {
    display: none;
  }
}
.account .choice {
  @include flex;
  flex-direction: row;
  margin-top: 2rem;
  span {
    display: block;
  }
}
.password-info {
  margin-bottom: 1.5rem;
  border: none;
  display: block;
  @include fontDefault(2, 2.6rem);
}
/*Contact*/
label{
  font-weight: 600;
}
form {
  .payment-option-inner{
    @extend .fieldset, .coupon;
  }
  .field{
    .control{
      margin: 1rem 0;
      text-align: left;
    }
    &set{
      padding-bottom: 1.5rem;
      &.coupon{
        padding-bottom: 0;
        input{
          background: $colorWhite;
        }
      }
      &:after {
        margin: 1.5rem 0 0;
        content: attr(data-hasrequired);
        color: $colorError;
        font-size: 1.4rem;
        display: block;
      }
      .control {
        input, select {
          width: 100%;
        }
        input#captcha_user_login {
          text-transform: none;
        }
        textarea {
          width: 100%;
          padding: 1rem;
        }
      }
    }
    &.comment{
      .control{
        height: 5rem;
      }
    }
  }
  .note {
    margin-bottom: 2rem;
  }
}
/*neue adresse tooltip checkout*/
.control{
  &._with-tooltip{
    @include flex(space-between, $wrap: true);
    position: relative;
    .note{
      margin-top: 1rem;
    }
    .field-tooltip {
      cursor: pointer;
      @include overlay(0, 7px, null, 5.4rem, $right: true);
      @include flex(centery);
      &.toggle{
        #tooltip {
          &:before {
            @include fontIcon(2, 400);
            color: #333;
            content: "";
          }
          &-label {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }
        }
      }
      &._active{
        .field-tooltip-content {
          display: block;
        }
      }
      &-content{
        background: $colorGrey;
        @include fontDefault(1.4);
        padding: 1.2rem;
        display: none;
        @include overlay(50%, 3rem, 27rem, null, $right: true);
        text-transform: none;
        transform: translate(0, -50%);
        word-wrap: break-word;
        z-index: 2;
        max-width:calc(100vw - 6.7rem);
      }
    }
    .input-text {
      -ms-flex-preferred-size: calc(100% - 4rem);
      flex-basis: calc(100% - 4rem);
    }
  }
}
fieldset{
  &.street {
    &.admin__control-fields {
      &.required {
        padding-bottom: 0;
        > .label {
          display: none;
          padding-bottom: 0;
        }
      }
    }
  }
}
#opc{
  &-new-shipping-address{
    .field{
      &.choice {
        @include flex(flex-start, center);
        line-height: 1;
        input{
          &[type="checkbox"]{
            margin-top: 0;
          }
        }
        label.label{
          line-height: 1;
        }
      }
    }
    #shipping-save-in-address-book{
      width: 25px;
      margin-bottom: 0;
    }
    input, select{
      width: 100%;
    }
  }
}
.required > label, ._required > label {
  position: relative;
  &:after {
    @include fontIcon(.8);
    content: "#{$iconAsterix}";
    color: $colorRed;
    position: absolute;
    top: .1rem;
    line-height: 1;
    margin-left: .5rem;
  }
}
.tocompare {
  &:before {
    content: "#{$iconCompare}";
    @include fontIcon;
    margin-right: 0.25rem;
  }
}
.field{
  margin-top: .3rem;
  &.region {
    display: none;
  }
}
.form{
  &-discount{
    .label{
      display: none;
    }
    .action {
      width: 100%;
      margin: 1.5rem 0 0;
      &s-toolbar {
        > * {
          width: 100%;
        }
      }
    }
  }
}
