@mixin buttonWhite {
  color: $colorWhite;
  border-color: $colorWhite;
  &:hover{
    border-color: $colorSecondary;
    background: $colorSecondary;
  }
}
button{
  cursor: pointer;
}

.primary + .secondary{
  .action {
    margin-left: 2rem;
    @include breakpoint(size480px){
      margin-left: 0;
      margin-top: 2rem;
    }/* 480px */
  }
}
.secondary {
  @include flexAlign(center, center, flex);

}
.primary, .secondary{
  @include breakpoint(size480px){
    width: 100%;
  }
}
.action + .action{
  margin-left: 1rem;
  @include breakpoint(size480px){
    margin-left: 0;
    margin-top: 2rem;
  }/* 480px */
}

.action{
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  border-radius: if($rounded-corners, 5px, null);
  display: inline-block;
  text-decoration: none;
  letter-spacing: .06rem;
  word-break: normal;
  hyphens: none;
  text-transform: uppercase;
  @include border(2px, $colorPrimary);
  @include fontDefault($fontButton , 2rem, $colorPrimary, normal);
  @include transition;
  padding: $buttonPadding;
  @include breakpoint(size768px){
    background: $colorSecondary;
    @include border($color: $colorSecondary);
    color: $colorWhite;
    font-size: 1.4rem;
    &.showcart, &.toggle, &.towishlist, &.sorter-action{
      background: transparent;
      border:0;
    }
  }
  &:hover{
    background: $colorSecondary;
    @include border($color: $colorSecondary);
    color: $colorWhite;
  }
  &.primary{
    position: relative;
    span{
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.secondary{
    @include border($color: $colorPrimary);
    @include breakpoint(size768px){
      @include border($color: $colorSecondary);
    }
  }
  &-accept, &-dismiss{
    @extend .action;
  }
  &.clear {
    background: $colorPrimary;
    border-color: $colorPrimary;
    color: $colorWhite;
    margin-left: auto;
  }
  &-show{
    text-transform: none;
  }
  &.print{
    margin-top: 2rem;
  }
  &.view{
    border:0;
    @extend .no-hover;
    letter-spacing: 0;
    @include breakpoint(size480px){
      width: auto;
    }
  }
  &.disabled {
    border-color: $colorSuccess;
    background-color: $colorSuccess;
    pointer-events: all;
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.continue{
    margin-right: 1rem;
  }
  &.continue, &.clear, &.update{
    @include breakpoint(size1024px){
      width: 100%;
      margin: 0 {
        bottom: 1rem;
      };
    }
  }
  &.delete, &-delete,
  &.edit, &-edit,
  &.change-password,
  &-change-password,
  &.remove, &-remove,
  &.towishlist{
    @include border($size:0);
    @include fontDefault(0, normal);
    padding: 0;
    letter-spacing: 0;
    > span {
      display: none;
    }
    &:hover{
      @include border(0);
      background: initial;
      color: initial;
      &:before {
        color: $colorSecondary;
      }
    }
    &:before {
      @include fontIcon(1.6);
      color: $colorPrimary;
      display: inline-block;

    }
    @include breakpoint(size768px){
      background: transparent;
      color: inherit;
      @include border(0);
    }
    @include breakpoint(size480px){
      width: auto;
    }
  }
  &.delete, &-delete{
    &:before {
      content: $iconTrash;
    }
  }
  &.edit, &-edit{
    &:before {
      content: "#{$iconEdit}";
    }
  }
  &.change-password, &-change-password{
    &:before {
      content: $iconKey;
    }
  }
  &.remove, &-remove{
    &:before {
      content: $iconClose;
    }
  }
  &-apply{
    @extend .action, .primary;
  }
  &.apply, &-apply{
    width: 100%;
    background: $colorLighterGrey;
    border-color: $colorLighterGrey;
    color: $colorPrimary;
  }
  &.checkout{
    @include border($color: $colorSecondary);
    background: $colorSecondary;
    color: $colorWhite;
  }
  &s-toolbar {
    @include flex;
    @include breakpoint(size480px){
      @include flex($wrap: true);
    }
    .wishlist-index-index &{
      @include flex($wrap: true);
      margin-top: 0;
      @include breakpoint(size480px){
        .action + .action{
          margin-top:0;
        }
      }
      .primary{
        width: 100%;
        @include flex(flex-start, center, $wrap: true);

        gap: 1rem;
        > .action{
          margin-left: 0;
        }
      }
      .back{
        margin: {
          left: 0;
          top: 3rem;
        }
      }
    }
    .form-create-account &, .customer-container &{
      width: 100%;
    }
    .login-container &{
      width: 100%;
      margin-top: 4rem;
    }
  }
  table &{
    &.view{
      letter-spacing: 0;
      padding: 0;

      @include fontDefault(1.6, normal, null, 600, none);
    }
  }
  &.showcart{
    border:0;
    padding: 0;
    @extend .no-hover;
  }
  &.subscribe{
    border-color: $colorWhite;
    color: $colorWhite;
  }
  &.towishlist{

    &:before{
      content: $iconStar;
    }
  }
  &.tocart{
    background: $colorSecondary;
    color: $colorWhite;
    text-transform: uppercase;
    border-color: $colorSecondary;
    line-height: normal;
  }
  &-toggle, &.toggle{
    display: inline-block;
    padding: 0;
    margin-bottom: 1rem;
    @include border(0);
    color: $colorPrimary;
    background: transparent;
    @include breakpoint(size768px){
      &:hover{
        @include border(0);
      }
    }
    &:hover{
      @include border(0);
      background: transparent;
      color: initial;
    }
  }
  .product-options-bottom &{
    &.towishlist{
      padding: 1.4rem;
      background: $colorGrey;
      margin-left: 1.2rem;
      width: auto;
      &:before{
        opacity: .8;
      }
      @include breakpoint(size480px){
        margin-left: 0;
      }
    }
  }
  &.nav-toggle{
    display: inline-block;
    border: none;
    cursor: pointer;
    background: $colorSecondary;
    width: 5.6rem;
    line-height: 0;
    padding: 1rem 1.4rem;
    border:0;
    &:before, &:after, span {
      height: .3rem;
      display: block;
      background: $colorWhite;
    }
    &:before, &:after {
      content: "";
      width: 100%;
    }
    span {
      font-size: 0;
      width: 100%;
      margin-top: .8rem;
    }
    &:after {
      margin-top: .8rem;
    }
  }
  @include breakpoint(size480px){
    width: 100%;
  }
  &-hide-popup{
    &:hover{
      border-color: $colorSecondary;
    }
  }
} /* .action */
.captcha {
  flex-wrap: wrap;
  padding: 1.7rem 2rem;
  @include border($color: $colorInputBorder);
  margin: 2rem 0;
  .captcha {
    width: 100%;
    padding: 0;
    background: transparent;
    margin: 0;
    border: 0;
    &-image {
      @include flex($wrap:true);
      gap: 2rem;
      img{
        height: 44px;
      }
    }
  }
}
.block-dashboard {
  &-addresses, &-orders {
    .block{
      &-title{
        @include flexAlign(space-between, null, flex);
        justify-content: space-between !important;

        .action{
          @include fontDefault(1.6, 2.2rem, $colorSecondary, $transform: none);
          @include fontAdditional($weight: 500);
          letter-spacing: 0;
          padding: 0;
          &:before{
            display: none;
          }
          span{
            display: block;
          }
        }
      }
    }
  }
}

.no-hover{
  @include breakpoint(size768px){
    background: initial;
    color: initial;
    /*@include border(0);*/
  }
  &:hover{
    border-color: transparent;
    background: transparent;
    color: initial;
  }
}

