.webcrossing-widget-category-slider{
  padding: 3rem 0;
  @include border($color: $colorGrey, $position: top);
  margin-top: 10rem;
  .image{
    text-align: center;
    img{
      display: inline-block;
    }
  }
  .block {
    &-title {
      justify-content: center;
      padding: {
        top: 6rem;
      }
      @include outerContent;

    }
    &-content {
      position: relative;
      .toolbar{
        padding-bottom: 0;
      }
    }
  }
  .splide{
    @include outerContent;
    &__arrow {
      svg{
        display: none;
      }
      &.splide__arrow {
        height: auto;
        background-color: transparent;
        @include flexAlign(center, center);
        position: absolute;
        border-radius: if($rounded-corners, 0, null);
        &--prev, &--next {
          &:before{
            content: url($iconDir + "slide_right.svg");
          }
        }
        &--prev {
          left: -2rem;
          &:before{
            transform: rotate(180deg);
          }
        }
        &--next {
          right: -2rem;
          &:before{

          }
        }
      }
    }
  }
  .cms-home &{
    @include border(0);
    margin-top: 0;
  }
  @media only screen and (max-width: 1600px) {
    .block{
      &-content{
        padding: 0 5rem;
      }
    }
    .splide{
      position: inherit;
      &__arrow {
      }
    }
  }
}