.shipping{
  &-address {
    &-item {
      margin-bottom: 1.5rem;
      margin-right: 2%;
      width: 32%;
      padding: 2rem;
      border: 1px solid $colorBorder;

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      &.selected-item {
        border-color: $colorPrimary;
      }

      button.action {
        width: 100%;
        background: $colorPrimary;
        color: $colorWhite;
        margin-top: 2rem;
        margin-left: 0 !important;
        border-color: $colorPrimary;

        &:hover {
          color: $colorPrimary;
          background: transparent;
        }
      }

      &s {
        @include flex($wrap: true);
      }
    }
  }
  &-information {
    margin-top: 2rem;

    &-title {
      font-weight: bold;
      padding-bottom: 1.3rem;
      border-bottom: 1px solid $colorBorder;
      margin-bottom: 1.4rem;
      @include flex;

      > span {
        &:first-child {
          width: 100%;
        }
      }

    }
  }
  &-cost-details{
    color: $colorSecondary;
    a{
      color: $colorSecondary;
      border: 0 !important;
      text-decoration: none;
    }

  }
  .cart-container &{
    display: none;
  }
  @include breakpoint(size1024px) {
      &-information{
        margin-top: 0;
      }
  }
  @include breakpoint(size768px) {
    &-address-item{
      width: 49%;
      &:nth-of-type(3n){
        margin-right: 2%;
      }
      &:nth-of-type(2n){
        margin-right: 0;
      }
    }
    &-information{
      margin-top: 4rem;
    }
  }
  @include breakpoint(size480px) {
    &-address-item{
      width: 100%;
      margin-right: 0;
      &:nth-of-type(3n){
        margin-right: 0;
      }
      &:nth-of-type(2n){
        margin-right: 0;
      }
    }
  }
}