.account{
  p.toolbar-amount{
    display: none;
  }
}
.customer-container{
  @include flexAlign(space-between, flex-start, flex);
  padding:  {
    top: 3rem;
  }
  gap: 3rem;
  .account &{
    padding:  {
      top: 0;
    }
    @include flex($wrap: true);
    .block-content{
      @include flex(flex-starty, $wrap: true);
      gap: 3rem;
      .box{
        width: calc(33% - 3rem);
        padding: 2rem {
          right: 6.5rem;
        };
        @include border(1px, $colorSwatchGrey);
        position: relative;
        @include fontDefault(1.6);
        &-actions{
          position: absolute;
          @include overlay(2rem, 2rem, null, null, $right: true);
          @include flexAlign(space-between, up);
        }
        &-title{
          display: block;
          margin-bottom: 2rem;
        }
      }
    }
  }
  .actions-toolbar{
    margin-top: 1.5rem;
  }
  .block, .ordered{
    width: 100%;
    &-title{
      text-align: left;
      padding: {
        top: 0;
        bottom: 1rem;
      }
      margin-bottom: 2rem;
      @include flexAlign(flex-start, null);
      @include border($position: bottom);
      strong{
        @include fontAdditional($weight: 600);
      }
    }
  }
  form{
    @include flex(flex-start,$wrap: true);
    width: 100%;
    gap: 3rem;
    .legend{
      @extend .block-title;
      width: 100%;
      @include fontAdditional($weight: 600);
    }
    fieldset{
      width: calc(50% - 1.5rem);
      &.additional_info{
        margin-top: -17rem;
      }
      &.login{
        width: 100%;
      }
    }
    .street .field.primary > .label{
      display: none;
    }
  }
  @include breakpoint(size1200px){
    @include flex($wrap: true);

    .account &{

      .block-content{
        .box{
          width: calc(50% -  1.5rem);
        }
      }
    }
  }
  @include breakpoint(size1024px){
    form{
      @include flex($wrap: true);
      fieldset{
        width: 100%;
        &.additional_info{
          margin-top: 0;
        }
      }
    }
  }
  @include breakpoint(size768px){
    padding-bottom: 3rem;
  }
  @include breakpoint(size480px){
    .account &{

      .block-content{
        .box{
          width: 100%;
        }
      }
    }
  }
}
.order {
  &-title{
    @extend .block-title;
  }
  &-products-toolbar {
    @include fontDefault(1.4, 2rem);
    @include fontAdditional(null, 600);
    text-align: right;
    margin: 0;

    select {
      @include fontDefault(1.4, 2rem);
      @include fontAdditional(null, bold);
      text-align: right;
    }
  }
  &s-history{
    width: 100%;
  }
}
.limiter {
  margin: {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  @include flexAlign(null, center, flex);
  padding: {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  select#limiter {
    width: 7.5rem;
    margin: {
      left: 0.5rem;
      right: 1rem;
    }
  }
}