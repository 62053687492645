.webcrossing-widget-category-tab{
  background: no-repeat center center;
  background-size: cover;
  margin-top: 10rem;
  padding-bottom: 12rem;
  position: relative;
 /* &:after{
    content: "";
    background: rgba($colorPrimary, .8);
    @include overlay;
    z-index: 0;
  }*/
  > *{
    position: relative;
    z-index: 1;
  }
  .block{
    &-title{
      padding-top: 9rem;
      h2{
        color: $colorWhite;
        padding-bottom: 10.1rem;
      }
    } /* .block-title */
    &-content{
      @include outerContent;
    }/* .block-content */
  }
  .categories{
    ul{
      position: relative;
      @include flexAlign( center, center, flex);
      margin-bottom: calc(153.44 / 790.34 * 100%);
    }
    li{
      text-align: center;
      width: 100%;
      &.active{
        color: $colorSecondary;

        > h2{
          color: $colorSecondary;
          position: relative;
          &:after{
            content: "";
            @include overlay(100%, $height: 2px);
            background: $colorSecondary;
            margin-top: .3rem;
          }
        }
      }
    }
    h2{
      display: inline-block;
      cursor: pointer;
      @include fontDefault(2.2);
      @include fontAdditional($colorWhite, 600, uppercase);
    }
    .content{
      margin-top: 4.7rem;
      @include flexAlign(center, null , flex);
      @include overlay(100%, $height: auto);
      gap: 3rem;
    }
    .sub-category{
      background: no-repeat;
      background-size: cover;
      @include border;
      width: 100%;
      @include flexAlign(center, center, flex);
      padding-top: calc(153.44 / 790.34 * 100%);
      position: relative;
      a{
        @include overlay;
      }
      a{
        z-index: 2;
        @include flexAlign(center, center , flex);
      }
      h2{

      }
    }
  }
  @include breakpoint(size1024px){
    .block-title{
      padding-top: 5rem;
      h2{
        margin-bottom: 0;
        padding-bottom: 4.5rem;
      }
    }
    .categories {
      ul {
        @include flex($wrap:true);
        gap: 2.4rem;

        li {
          width: auto;
        }
      }
    }
  }
  @include breakpoint(size768px){
    .categories{
      .content{
        gap: .5rem;
      }
      .sub-category{
        background-position: center;
        padding-top: calc(153.44 / 200.34 * 100%);
      }
      ul{
        margin-bottom: calc(153.44 / 200.34 * 100%);
      }
    }
  }
}