$mmenu: right;
$justify: right;
@mixin mobileMenu($state: null, $local: $mmenu){
  @if $state == close {
    position: fixed;
    top: 0;
    width: 45rem;
    max-width: calc(100% - 6.8rem);
    height: 100%;
    #{$mmenu}:-90%;
  } @else if $state == open {
    #{$mmenu}:0;
  }
}

@if $justify == right{
  $justify: flex-end;
}@else if $justify == left{
  $justify: flex-start;
}

.mmenu, .page {
  &-wrapper{
    @include transition;
  }
}
.page-wrapper{
  /*overflow-x: hidden;*/
  .mmenu-opened &{
    max-height: 100vh;
    overflow: hidden;
  }
}
body{

}
.mmenu {
  &-opened{
    overflow: hidden;

  }
  &-content{
    width: 45rem;
    max-width: calc(100% - 6.8rem);
    background: $colorWhite;
    overflow: auto;
    max-height: 100vh;

  }
  &-wrapper {
    display: none;
    position: fixed;
    overflow: auto;
    z-index: 11;
    top: 0;
    width: 100%;
    #{$mmenu}: -100%;
    background: rgba($colorPrimary, .9);
    min-height: 100vh;
    &.opened{
      @include mobileMenu(open);
      max-width: 100%;
      .mmenu-header-close{
        @include overlay(2rem, null, auto, auto, fixed);
        #{$mmenu}: calc(100% - 6.6rem);
      }
    }
    .navigation {
      ul{
        @include flex($wrap: true);
        li{
          @include flex($direction: column);
          width: 100% !important;

          &.level0{

            > a{
              padding:2rem 3.5rem;
            }
          }
          a{
            color: $colorPrimary;
            width: 100%;
          }
        }
        &:hover{
          a{
            color: $colorPrimary;
          }
        }
      }
    }
  }
  &-header, &-top{
    padding: 2.7rem 2rem;
    &-close{
      @include overlay(2rem, null, auto, auto, relative);
      @include transition;
      .nav-toggle{
        background: transparent;
        &:before, &:after{
          display: none;
        }
        span{
          &:before{
            content: $iconClose;
          }
          line-height: normal;
          background: transparent;
          @include fontIcon(4);
          color: $colorWhite;
          height: auto;
        }
      }
    }
  }
  &-header{
    @include flex(space-between, $wrap: true);
    overflow-y: visible;
    &-search{
      padding: 1rem 0;
      width: 100%;
      .block {
        &-search{
          .block {
            &-content{
              position: relative;
              padding: 0;
              z-index: 0;
              .label{
                display: none;
              }
              form {
                @include flexAlign(null, center, flex);
                @include border(1px, $colorSwatchGrey);
                .search {
                  width: 100%;
                  label, .nested {
                    display: none;
                  }
                }
                input {
                  border: 0;
                  background: transparent;
                }
                .actions {
                  width: auto;
                  button {
                    padding: .2rem 1rem;
                    background: transparent;
                    border: 0;

                    span {
                      display: none;
                    }

                    &:before {
                      content: "\f002";
                      @include fontIcon;
                      font-size: 2rem;
                      color: $colorPrimary;
                      margin-right: 0;
                    }
                  }
                }
              }
              .search{
                width: 100%;
              }
            }
          }
        }
        &-title{
          display: none;
        }

      }
    }
    &-account{
      padding: 1rem 0;
      a{
        color: $colorSecondary;
      }
      i{
        font-size: 2.2rem;
        margin-right: 1rem;
        font-weight: 300;
      }
    }
    &-account, &-language{
      width: auto;
    }
    &-language{
      @extend .header-main-language;
      .switcher {
        &-trigger{
          padding-left: .5rem;
          color: $colorPrimary;
        }
        .options ul.dropdown {
          height: auto;
        }
      }
      &.section{
        @include flexAlign(flex-end, center);
        .switcher-options li.view {
          &-en, &-de {
            background: $colorWhite;
            a {
              padding: {
                left: .5rem;
                top:1rem;
                bottom:1rem;
              };
            }
          }
        }
      }

    }
  }
  &-main{
    padding: 0 {
      bottom: 3rem;
    };
    ul{
      &.level0{
        padding-bottom: 2rem;

      }
    }
    li{
      &.prev{
        span{
          cursor: pointer;
        }
      }
      &.level {
        &0 {
          a{
            border-bottom: 1px solid rgba($colorSecondary, .1);
          }
          .submenu{
            li{
              &.level1{
                padding-right: 0;
                > a{
                  padding: 2rem;
                }
              }
              &.level2{
                @include flex(space-between, center,row-reverse);
                padding-bottom: 0;

                &:before{
                  display: none;
                }
                > a{
                  padding: 1rem{
                    left: 2rem;
                    right: 2rem;
                  }
                }
              }
            }
          }
        }
        &1{
          font-weight: 300;
          font-size: 1.5rem;
          a{
            padding-top: .5rem;
            padding-bottom: .7rem;
          }
          .mm-btn_next{
            height: 3rem;
          }
        }
      }
      a{
        padding: 2rem 3.5rem;
        box-shadow: none;
        border:0;
        &.mm-btn_next{
          height: 6rem;

        }
        &.level-top{
          display: block;
          @include font;
          font-size: 1.7rem;
          color: $colorSecondary;
          font-weight: bold;
        }
        &:before{
          display: none;
        }
        &:after{
          transform: rotate(223deg);
          transition: all ease .2s;
        }

      }
    }
    .navigation.slide{
      overflow: hidden;
      ul{
        @include transition;
        /*transform: translate(-100%, 0);*/
        position: relative;
        left: 100%;

        li{
          @include transition;
          left: 0;
          position: relative;
          max-width: 100%;
          border-right: 0;
          width: 100%;
          &.has-active{
            > a{
              background: $colorSecondary;
              color: $colorWhite;
            }
          }
          &.active{
            border-top: 0;
            > a{
              background: $colorSecondary;
              color: $colorWhite;
            }
          }
          &.prev{
            @include flex($flex: false, $direction: row);
            padding: 2rem;
            span{
              font-size: 1.6rem;
            }
            .prev{
              display: inline-block;
              padding: 0 {
                right:2rem;
              }
            }
          }
          a{
            @include transition;
            left: 0;
          }
        }
        &.opened{
          overflow: hidden;
          left: 0;
          position: relative;
          top: 0;
          max-height: initial;
        }
        &.remove{
          > li {
            > a, &.prev {
              position: absolute;
              left: -100%;
            }
            &:not(.opened) {

            }

          }
        }
      }
      > ul{
        position: relative;
        left: 0;
        li{
          a{
            @include flexAlign(space-between, center, flex);
            padding: 2rem;
            font-weight: normal;
            .toggle{
              padding: 0 2rem;
            }
          }
        }
      }
      .submenu{
        @include transition;
        display: block;
        position: absolute;
        overflow: hidden;
        max-width: 100%;
        width: 100%;
        border-top:0;
        padding: 0;
        box-shadow: none;
        top: 0;
        margin-top: 0;
      }
    }
  }
  &-footer{
    &-navigation{
      &-top {
        padding: 2rem 0;
        a {
          @include fontDefault(1.6, 4rem);
          padding: 0 2rem;
          display: block;
        }
      }
      &-main{
        padding: 2rem;
        ul{
          @include flex(centery,$wrap: true);
        }
        li{
          text-align: center;
          i{
            @include fontIcon(3);
            margin: 0 2.5rem;
          }
          &:first-child{
            width: 100%;
            padding-bottom: 2.5rem;
          }
          a{
            display: inline-block;
            width: 100%;
            font-size: 1.6rem;
            padding: .4rem 3.5rem;
          }
        }
      }
    }
  }
}

@include breakpoint(size1024px) {
  body{
    &:before{
      display: block;
    }
  }
  .mmenu-wrapper {
    @include flexAlign(#{$justify}, null, flex);
  }
}



