.title{
  @include fontDefault(2, normal);
  @include fontAdditional($weight: 600);
  padding-bottom: 1.6rem;
  display: block;
}
.callforprice-action{
  .price-details{
    display: none;
  }
}
.block-title{
  @include flex(centery, $wrap: true);
  padding-top: 10rem;
  h2{
    width: 100%;
    text-align: center;
    @include fontDefault(4, 6rem);
    @include fontAdditional($colorFontDefault, 600);
    padding-bottom: 6rem;
  }
}
.to-top{
  z-index: 9;
  @include overlay(3rem, -10rem, 5rem, 5rem, fixed, $right: true, $bottom: true);
  background: $colorSecondary;
  @include fontAdditional($colorWhite, 300);
  @include fontDefault(5, 5rem);
  @include flexAlign(center, center, flex);
  @include transition;
  &.active{
    right: 5rem;
  }
}
.contact{
  .fieldset{
    @include flex($wrap: true);
    gap:2rem;
    > div{
      width: calc(50% - 1rem);
    }
  }
}
/**** Columns *****/
.page-layout{
  &-1column{
    &:not(.cms-home){
      .column{
        @include outerContent;
       /* > div, > form{
          @include outerContent;
          width: 100%;
        }*/
        .results{
          padding-top: 10rem;
        }
        .inner-container, .cart-empty, .checkout-success, > .contact{
          padding-top: 3rem;
        }
        /*.inner-container, .cart-empty, .checkout-success, > .contact{
          @include outerContent;
          @include flexWrap(wrap, flex);
          flex-direction: column;
          padding-top: 3rem;
        }*/
      }
    }
  }
  &-2columns {
    &-left, &-right {
      &.catalogsearch-result-index{
        .sidebar.sidebar-main:empty{
          display: none;
          background: pink;
        }
        .column{
          width: 100%;
        }
      }
      .column {
        width: calc(100% - 30rem);
        &s {
          @include outerContent;
          @include flexAlign(space-between, null, flex);
          @include flexDirection(row-reverse);
          margin-bottom: 10rem;
        }
      }
    }
    &-right{
      .columns{
        flex-direction: row-reverse;
      }
    }
  }
  &-category-full-width{
    .column {
      padding: {
        left: 2rem;
        right: 2rem;
      }
    }
  }
}
.page-products, .account{
  .columns{
    padding-top: 3rem;
  }
}
.customer-account-logoutsuccess {
  .column {
    @include outerContent;
  }
}
/**** Filter *****/
.filter{
  @include flex;
  &-content{
    width: 100%;
  }
  &-options{
    @include flexDirection(column);
    &-item{
      max-width: 36rem;
      width: 100%;
      position: relative;
      z-index: 2;
      margin-left: 3rem;
      @include transition;
      &:first-child{
        margin-left: 0;
      }
      &.active{
        .filter {
          &-options {
            &-title{
              &:after{
                transform: rotate(180deg);
              }
            }
            &-content {
              ol {
                max-height: 100vh;
                overflow: auto;
                transition-duration: 1s;
              }
            }
          }
        }
      }
    }
    &-title{
      @include fontDefault(2.2, 3.1rem);
      @include fontAdditional($colorDarkGrey2, 600);
      cursor: pointer;
      padding: {
        bottom: 3.5rem;
      }
      position: relative;
      z-index: 1;
      @include transition;
      &:after{
        @include overlay(null, 0, null, null, $right: true);
        content: url($iconDir + "arrow_open.svg");
        vertical-align: center;
        @include transition;
      }
      &.active{
        padding: {
          bottom: 1.5rem;
        }
        &:after{
          transform: rotate(180deg);
        }
      }
      &:first-child{
        margin-left: 0;
        padding: {
          bottom: 1.5rem;
        }
        cursor: initial;
        &:after{
          display: none;
        }
      }
    }
    &-content{
      @include border(1px, $colorSwatchGrey, $position: bottom);
      margin-bottom: 3.5rem;
      width: 100%;
      @include transition;
      ~ .filter-options-content{
        max-height: 0;
        overflow: hidden;
      }
      &.active{
       max-height: 999rem;
      }
      ol{
        padding-bottom: 3.5rem;
        li{
          @include fontDefault(1.6, 3.2rem);
          a{
            @include fontAdditional($colorDarkGrey2);
          }
          .count{
            &:before{
              content: "(";
              margin-left: .8rem;
            }
            &:after{
              content: ")";
            }
          }
        }
      }
    }
  }
  &-current{
    padding: 5rem 0 2rem;
    .filter{
      &-label{
        @include fontAdditional($weight: bold);
        white-space: nowrap;
      }
      &-value{
        padding-left: .5rem;
      }
    }
    &-subtitle{
      padding-right: 5rem;
      font-weight: 700;
      font-size: 2rem;
      color: $colorSecondary;
    }
    &-wrap{
      @include flex;
      align-items: center;
      .item{
        margin: 0 1rem 0 .5rem;
        padding: .8rem 2rem;
        color: $colorSecondary;
        border: 1px solid $colorBorder;
        border-radius: .4rem;
        line-height: normal;
        font-size: 1.6rem;
        font-weight: 500;
        &s{
          @include flex;
          align-items: center;
        }
      }
    }
    .item{
      @include flex(space-between, center);
      margin-top: 1rem;
      @include fontDefault(1.6);
      .action{
        margin-left: auto;
      }
    }
  }
  &-actions{
    margin-bottom: 3.5rem;
    padding-bottom: 3.5rem;
    @include border(1px, $colorSwatchGrey, $position: bottom);

    .action{
      width: 100%;
    }
  }
  &-count-label{
    display: none;
  }
}
#toolbar-amount{
  display: none;
}
.fotorama{
  &-item{
    .fotorama{
      &__nav{
        &__shaft{
          @include breakpoint(size768px){
            padding-top: 3rem;
          }

        }
        &--dots{
          .fotorama__nav__frame{
            width: 4.3rem;
            height: auto;
            margin-right: .5rem;
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
      &__dot{
        border:0;
        background: $colorGrey;
        width: 4.3rem;
        height: .5rem;
        border-radius: 0;
        left: initial;
        top: initial;

      }
      &__active{
        .fotorama__dot{
          background: $colorSecondary;
          border-color: $colorSecondary;
        }
      }
      &__wrap{
        .fotorama{
          &__thumb-border{
            @include border(1px, $colorPrimary);
            background: transparent;
            box-shadow: 0;
          }
        }
      }
    }
  }
  &__stage__frame img.fotorama__img{
    left: 0;
    top: 0;
    transform: translate(0,0);
  }

}
/**** Tooltip *****/
.tooltip{
  @include flexAlign(space-between, flex-start, flex);
  @include flexDirection(column);
  @include fontDefault(1.5);
  text-align: left;

  &.wrapper{
    margin-bottom: 2rem;

    padding: 0 2.5rem;
  }

  &.toggle{
    width: 100%;
    cursor: pointer;
    @include flexAlign(space-between, center, flex);
    @include flexDirection(row);
    text-transform: none;
    margin-bottom: .5rem;
    position: relative;
    letter-spacing: 0;
    &:before{
      @include overlay(100%, 0, 100%, 2px);
      margin-top: .5rem;
      content: "";
      background: $colorGrey;
    }
    &:after{
      content: "+";

    }
  }
  &.content{
    width: 100%;
    background: $colorGrey;
    padding: .3rem 1rem;
    display: none;
    .subtitle{
      display: none;
    }
    &.active{
      display: block;
    }
    .label{
      @include fontAdditional($weight: 600);
      margin-top: .5rem;
    }

  }
}

/**** Toolbar *****/
.products + .toolbar-products{
  margin-top: 4rem;
  padding-bottom: 0;
  .pages {
    display: block;
  }
  .sorter{
    display: none;
  }
}
.toolbar {
  @include flexAlign(right, null, flex);
  &-products{
    padding-bottom: 4rem;
  }
  .limiter, .sorter{
    @include flexAlign(center, center, flex);
    position: relative;
    label{
      color: $colorPrimary;
      @include fontDefault(1.6);
      @include fontAdditional($colorPrimary, normal);
    }
  }
  .limiter{
    padding: 0;
    margin: 0;
    select{
      margin-left: 0;
    }
    label{
      display: none;
    }
  }
  select {
    @include fontDefault(1.6);
    @include fontAdditional($weight: normal);
    width: auto;
    cursor: pointer;
    padding: 0.7rem 0.5rem;
    background: transparent;
    position: relative;
    margin: 0 1rem;
    @include border;
    z-index: 2;
  }
  option{
    @include fontDefault(1.6, normal);
    @include fontAdditional;
  }
  .sort {
    &-asc {
      &:before {
        content: "#{$iconSortAsc}";
      }
    }
    &-desc {
      &:before {
        content: "#{$iconSortDesc}";
      }
    }
    &er {
      &-action {
        &.action {
          padding: 0;
          border:0;
          &:hover{
            background: transparent;
            color: initial;
          }
        }
        &:before {
          @include fontIcon;
        }
        span {
          display: none;
        }
      }
    }
  }
  &.customer-addresses-toolbar {
    @include fontDefault(1.4, 2rem);
    @include fontAdditional($weight: bold);
    text-align: right;
    margin: 0;
    width: 100%;
  }
  /*Toolbar Sorter*/
  &-sorter {
    text-align: right;
    align-items: center;
    z-index: 1;
    .sorter {
    }
  }
  .pages {
    display: none;
  }
}

/**** Pages *****/
.pages{
  width: 100%;
  > strong {
    display: none;
  }
  ul {
    @include flexAlign(center, center, flex);
    padding: 2rem;
    gap: .3rem;
    li {
      @include flexAlign(center, center, flex);
      &.item{
        a, strong {
          @include fontDefault($color: $colorPrimary);
          text-align: center;
          font-weight: normal;
          @include flexAlign( center, center, flex);
          border:0;
          padding: 0;
          width: 4.5rem;
          height: 4.5rem;
          span{
            display: inline-block;
            width: 100%;
            text-align: center;
          }
        }
        a{
          background: $colorGrey;
          &:hover{
            background: $colorPrimary;
            color: $colorWhite;
          }
        }
        .label{
          display: none;
        }
      }
      &.pages{
        &-item {
          &-previous, &-next{
            a {
              &:before{
                content: "#{$iconPagination}";
                @include fontIcon(1, 900);
                text-align: center;
                display: inline-block;
                width: 100%;
              }
            }
          }
          &-previous {
            a {
              &:before{
                transform: rotate(180deg);
              }
              span {
                display: none;
              }
            }
          }
          &-next{
            a{
              span {
                display: none;
              }
            }
          }
        }
      }
      &.current{
        strong {
          background: $colorSecondary;
          color: $colorWhite;
        }
      }
    }
  }
}

.products-grid + .toolbar-products{
  .limiter, .sorter{
    display: none;
  }
  .pages{
    display: block;
  }
}

/**** Pages Titles *****/
.breadcrumbs{
  @include outerContent;
  padding-bottom: 2rem;
  padding-top: 6rem;
  .item{
    @include fontDefault(1.5);
    a{
      color: $colorLightGrey;
      &:after{
        content: "/";
        margin: 0 1rem;
      }
    }
    strong{
      @include fontAdditional($weight: normal);
    }
    &s{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include flexAlign(flex-start, center, flex);
    }
  }
}
.category-page-header, .cms-page-header{
  background: $colorGrey;
}/* .category-page-header */
.cms-page-view{
  .inner-container{
    padding: {
      top: 3rem;
      bottom: 3rem;
    }
     /*@include outerContent;*/
  }
}
.page-title-wrapper{
  @include outerContent;
  padding: {
    top: 2rem;
    bottom: 2rem;
  }
  text-align: left;
  color: $colorPrimary;
  h1{
    @include fontDefault(4, 4rem);
    @include fontAdditional($colorPrimary, 500)
  }
  & + .breadcrumbs{
    padding-top: 0;
  }
}