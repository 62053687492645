.webcrossing-widget-image-slider{
  position: relative;
  z-index: 1;
  .block-content{
    .splide {
      &__pagination__page{
        width: 4.3rem;
        height: .3rem;
        border-radius: 0;
        opacity: .4;
        margin: 0 .3rem;
        background: $colorWhite;
        &.is-active{
          opacity: .9;
          transform: scale(1);
        }
      }
      &__slide{
        img.main{
          min-width: 100%;
        }
      }
      &__arrow {
        background: transparent;
        border-radius: 0;
        height: auto;
        opacity: 1;
        svg{
          display: none;
        }
        &--prev, &--next {
          transition: all ease .2s;
          position: absolute;
          &:before{
            content: url($iconDir + "slide_right_w.svg");
            color: $colorWhite;
          }
        }
        &--prev {
          left: 1rem;
          &:before {
            transform: rotate(180deg);
          }
        }
        &--next {
          right: 1rem;
        }
      }
    }
    ul{
      li{
        position: relative;
        overflow: hidden;
        .image{
          position: relative;
          .mobile{
            display: none;
          }
          @include breakpoint(size768px){
            .desktop{
              display: none;
            }
            .mobile{
              display: block;
              width: 100%;
            }
          }
          &:after{
            content: "";
            @include overlay;
            background: rgba($colorBlack, .2);
            z-index: 2;
          }
        }
        .info{
          @include outerContent;
          @include overlay(50%, 50%, null, auto);
          @include flexDirection(column, flex);
          @include flexAlign(null, flex-start);
          padding:{
            left: 5rem;
            right: 5rem;
          }
          max-width: 163rem;
          z-index: 3;
          text-align: left;
          transform: translate( -50%, -50%);
          &.right{
            text-align: right;
            @include flexAlign(flex-end, initial);
            h2{
              text-align: right;
            }
          }
          &.center{
            @include flexAlign(null, center);
            text-align: center;
            h2{
              text-align: center;
            }
          }
          @include breakpoint(size768px){
            max-width: 90%;
          }
          h2{
            @include fontDefault(4, 6rem);
            @include fontAdditional($colorWhite, 600, uppercase);
            padding-top: 2.1rem;
            width: auto;
          }
          h3{
            padding-top: 1rem;
            width: auto;
            @include fontDefault(2.5, 3.8rem);
            @include fontAdditional($colorWhite, normal, uppercase);
          }
          > p{
            letter-spacing: 1.25px;
            font-size: 1.87rem;
            text-align: left;
          }
          .actions{
            @include flex;
            padding-top: 1.2rem;
            gap: 2rem;
            a{
              @include buttonWhite;
              @include flexAlign(center, center, flex);
              text-transform: uppercase;
              &:hover{
                border-color: $colorSecondary;
              }
            }
          }
          &.white{
            color: $colorWhite;
            h2{
              color: $colorWhite;
            }
          }
        }
      }
    }
  }
  @include breakpoint(size1024px){
    .block-content {
      ul li .info {
        h2 {
          @include fontDefault(2.8, normal);
        }
        h3 {
          @include fontDefault(2, normal);
        }
      }
    }
  }
  @include breakpoint(size768px){
    .block-content {
      .splide__arrow {
        &--prev {
          left: 1rem;
        }
        &--next{
          right: 1rem;
        }
      }
      ul li .info {
        .actions {
          gap: 2rem;
          a {
            background: transparent;
            margin: {
              top: 0;
            };
          }
        }
      }
    }
  }
  @include breakpoint(size480px){
    .block-content ul li .info {
      max-width: 100%;
      width: 100%;
      h2 {
        line-height: normal;
      }
    }
  }
}
.elementor-widget.image-slider {
  margin: 0 auto {
    bottom: 8rem;
  };
  @include breakpoint(size768px){
    margin-bottom: 4rem;
  }
  .widget-content {
    margin-top: 0;
  }
  ul {
    margin-bottom: 0;
    li{
      position: relative;
      > &:not(:first-child) {
        display: none;
      }
      .overlay {
        @include overlay;
        @include flex(centery, $direction: column, $wrap:true);
        a.action.toexternal {
          z-index: 1;
          background-color: $colorWhite;
          color: $colorBlack;
          border-color: $colorWhite;
          &:hover {
            box-shadow: 0 0 1px 1px #fff;
          }
        }
        &:before {
          content: '';
          background-color: rgba(0, 0, 0, 0.3);
          @include overlay(0,0, null, null);
          right: 0;
          bottom: 0;
        }
      }
    }
  }
  &.content {
    max-width: $smallContent;
  }
}