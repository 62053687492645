.sticky{
  .header{
    &-main{
      position: fixed;
      top: 0;
      left: 0;
      &-container{
        .right{
          padding-bottom: 1.5rem;
        }
      }
      &-navigation{
        li.level0:hover {
          > ul {
            max-height: calc(100vh - 7rem) ;
          }
        }
      }
      &-logo{
        padding: 1rem 0;
        img{
          max-width: 7.5rem;
        }
      }
    }
  }
  & + main{
    padding-top: 12.6rem;
  }
  .navigation{
    > ul > li.level0 > a{
      padding-bottom: 2rem;
    }
  }
}
.header{
  &-top{
    background: $colorGrey;
    position: relative;
    z-index: 10;
    &-container{
      @include outerContent;
      padding: {
        top:.8rem;
        bottom: .8rem;
      };
      @include flexAlign(space-between, center, flex);
      @include fontDefault(1.6, 2.5rem);
      ul{
        @include flexAlign(flex-start, center, flex);
      }
    }
  }
  &-main{
    width: 100%;
    background: $colorWhite;
    position: relative;
    top: 0;
    z-index: 9;
    &-navigation{
      @include flexAlign(flex-start, stretch, flex);
      padding: {
        left: 3.4rem;

      }
      position: relative;
      &.mobile{
        display: none;
      }
      @include breakpoint(size1024px) {
        &.desktop{
          display: none;
        }
        &.mobile{
          @include flexAlign(center, center, flex);
        }
      }
    }
    &-container{
      @include outerContent;
      @include flexAlign(space-between, flex-end, flex);
      background: $colorWhite;
      .left, .right{
        @include flex;
      }
      .right{
        padding-bottom: 3.7rem;
        @include transition;
        .section{
          padding: 0 2rem;
        }

      }

    }
    &-account{
      i{
        &:before{
          content: url($iconDir + "account.svg");
        }
      }
    }
    &-language{
      padding: {
        right: 0 !important;
      }
      @include flexAlign(center, center, flex);
      .switcher {
        &-label {
          display: none;
        }
        .options {
          position: relative;
          .actions{
            &.toogle{
              cursor: pointer;
              display: inline-block;
              text-decoration: none;
            }
          }
          ul.dropdown{
            margin-top: 0.4rem;
            z-index: 100;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: none;
            @include overlay(100%, 0, $position: absolute);
          }
          &.active {
            overflow: visible;
            ul.dropdown{
              display: block;
            }
            .switcher-trigger{
              &:after{
                transform: rotate(-180deg);
              }
            }
          }
        }
        &-trigger{
          @include flexAlign(center, center, flex);
          &:after{
            content: "\f078";
            @include fontIcon(1.6);
            margin-left: 10px;
            transition: all ease .2s;
          }
        }
        &-options{

          strong.view{
            &-de, &-en{
              font-size: 0;
              line-height: normal;
              letter-spacing: 0;
              &:after{
                display: inline-block;
                width: auto;
                height: auto;
              }
            }
            &-de{
              &:after{
                content: url($imageDir + "icons/germany.png");
              }

            }
            &-en{
              &:after{
                content: url($imageDir + "icons/united-kingdom.png");
              }

            }
          }
          li.view{
            &-de, &-en{
              font-size: 0;
              line-height: normal;
              background: transparent;
              a{
                line-height: normal;
                display: inline-block;
                background: transparent;
                &:after{
                  display: inline-block;
                  width: auto;
                  height: auto;
                }
              }
            }
            &-de{
              a{
                &:after{
                  content: url($iconDir + "germany.png");
                }

              }
            }
            &-en{
              a{
                &:after{
                  content: url($iconDir + "united-kingdom.png");
                }

              }
            }
          }
        }
      }
    }
    &-logo{
      padding: {
        top:2rem;
        bottom: 2rem;
      };
      line-height: 0;
      img{
        max-width: 12.3rem;
        @include transition;
      }
    }
  }
}

.inner-top-container{
  li{
    margin-left: 1.55rem;
    padding-left: 1.55rem;
    @include border($position: left);
    &:first-child{
      padding-left: 0;
      margin-left: 0;
      @include border(0)
    }
  }
  ul{

  }

}