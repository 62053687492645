.mage-error {
  color: $colorPrimary;
}
.message {
  @include outerContent;
  display: block;
  margin: 0 auto;
  position: relative;
  padding: 1.5rem 2rem;
  width: 100%;
  .cart &{
    padding: 0;
    &.error{
      color: $colorError;
      background: transparent;
      > :first-child{
        &:before{
          display: none;
        }
      }
    }
  }
  a{
    display: contents;
  }
  > div{
    @include flex($wrap: false);
  }
  > *{
   /* @include flexWrap;*/
    @include flexAlign(left, center);
  }
  > *:first-child:before {
    line-height: 2.4rem;
    @include fontIcon(2.4);
    width: 4.5rem;
    text-align: center;
    margin-right: 1rem;
  }
  &.error {
    background: $colorError;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconError}";
      }
    }
  }
  &.success {
    background: $colorSuccess;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconError}";
      }
    }
  }
  &.info {
    background: $colorSuccess;
    color: $colorWhite;
    & > *:first-child{

      &:before{
        width: auto;
        color: #fff;
        content: "#{$iconInfo}";
      }
    }
  }
  &.empty {
    background: $colorPrimary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconInfo}";
      }
    }
  }
  &.warning {
    background: $colorPrimary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconWarning}";
      }
    }
  }
  &.notice {
    background: $colorError;
    color: $colorWhite;
    > :first-child::before{
      width: auto;
    }

    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconWarning}";
      }
    }
  }
  &.success,
  &.notice,
  &.warning,
  &.info,
  &.empty,
  &.error {
    a {
      color: $colorWhite;
      font-weight: bold;
      margin-left: .8rem;
      margin-right: .8rem;
      display: contents;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: $colorWhite;
        text-decoration: underline;
      }
    }
  }
  &s {
    .message{
      margin: 1.5rem 0 ;
      position: relative;
      padding: 1.5rem 2rem;
    }
    >*{
    }
    &.page {
      @include outerContent;
    }
    .cms-home &{
      margin-bottom: 0;
    }
    .checkout-index-index &{
      width: 100%;
      max-width: calc(100% - 39rem);
      @media only screen and (max-width: #{$tabletLandscape}) /* 1024px */ {
        max-width: 100%;
      }
    }
  }
  .checkout-index-index &{
    max-width: calc(100% - 39rem);
    margin: 0;
    &.error{
      max-width: calc(100% - 39rem);
      margin-bottom: 2rem;
    }
    &.notice{
      margin-bottom: 3rem;
    }
  }
  .checkout-index-index .checkout-shipping-address &{
    max-width: 100%;
  }
}

.field-error, .mage-error {
  color: $colorError;
  font-size: 1.4rem;
  width: 100%;
}
.password {
  &-weak, &-medium, &-strong, &-very-strong{
    @include fontDefault(1.6);
    .password-strength-meter > span{
      @include fontAdditional($weight: 600);
    }
  }
  &-weak {
    .password-strength-meter > span{
      color: $colorError;
    }
  }
  &-medium{
    .password-strength-meter > span{
      color: $colorInfo;
    }
  }
  &-strong{
    .password-strength-meter > span{
      color: $colorSuccess;
    }
  }
  &-very-strong{
    .password-strength-meter > span{
      color: $colorSuperSuccess;
    }
  }
}