.sidebar{
    width: 29rem;
    &:empty{
        display: none;
    }
    .account &{
        .block{
            .title{
                display: none;
            }
        }
        &-main {
            li{
                &.current{
                    border-left: 0.3rem solid $colorSecondary;
                }
                > a,  > strong {
                    display: block;
                    padding: 0.5rem {
                        right: 1.8rem;
                        left: 1.5rem;
                    };
                    font-size: 1.6rem;
                    color: $colorPrimary;
                }
                > a {
                    padding-bottom: 1.1rem;
                }
                > strong{
                    color: $colorSecondary;
                }
            }
            @include breakpoint(size768px){
                width: 100%;
                padding: {
                    bottom: 1.6rem;
                    top: 1.6rem;
                }
                margin-bottom: 3rem;
            }
            .account-nav {
                @include breakpoint(size768px){
                    padding-left: 0;
                    padding-right: 0;
                }
                &-content{
                    padding:1.5rem 0 ;
                }
            }
        }
    }

    /*** RESPONSIVE ***/
    @include breakpoint(size768px){
        width: 100%;
        padding-top: 4rem;
        @include border($position: top);
    }
}