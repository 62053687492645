.checkout{
  &-index{
    &-index{
      .column{
        @include outerContent;
      }
    }
  }
  &-container{
    @include flex(space-between, $wrap: true);
    padding-bottom: 6rem;
  }
  &-agreement {
    @include flex($wrap: true);
    button{
    }
    margin: 1rem 0;
    &:last-child{
      margin-bottom: 0;
    }
    input{
      width: auto;
    }
    .action{
      padding: 0;
      padding-left: 1.2rem;
      margin: 0;
      text-align: left;
      font-size: 1.4rem;
      background: transparent;
      color: $colorPrimary;
      border:0;
      &:hover{
        background: transparent;
        color: $colorPrimary;
      }

    }
    .label{
      max-width: calc(100% - 1.6rem);
    }
    &s {
     &:after{
       display: none;
     }
    }
  }
  &-shipping{
    &-method{
      margin-top: 4rem;
    }
  }
  &-billing{
    &-address{
      margin-bottom: 3rem;
    }
  }
  &-additional-information{
    @extend .checkout-shipping-method;
  }
  &-onepage-success{
    .page-main .actions-toolbar{
      .action{
        &.primary{
          margin-top: 3rem;
          display: block;
        }
      }
    }
  }
}
.note{
  font-size: 1.4rem;
  line-height: normal;
}
.address{
  > .field{
    .control {
      input, select {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
}
.billing{
  &-address{
    &-details{
      padding-left: 2rem;
      margin-top: 1rem;
      font-size: 1.5rem;
      br{
        display: block;
      }
      span{
        display: block;
      }
      .action{
        display: block;
        margin-top: 1.5rem;
      }
    }
    &-same-as-shipping-block{
      @include flex;
      input{
        width: auto;
      }
      label{
        width: 100%;
        padding-left: 1rem;
        font-size: 1.6rem;
      }
    }
  }
}
#shipping-new-address-form{
  @include flex(space-between, $wrap: true);
  > div{

  }
  > .field{

    width: 100%;

  }
}
#checkout {
  &-shipping-method-load {
    background: $colorGrey;
    padding: 2rem;
    margin-bottom: 3rem;

    table {
      tbody {
        border-bottom: 0;
        tr {
          padding-bottom: .7rem;
          @include flex;
          margin-bottom: .7rem;
          border-bottom: 1px solid $colorBorder;

          &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
.step {
  &-title {
    font-weight: bold;
    line-height: 1;
    font-size: 2.4rem;
    padding-bottom: 1.6rem;
    display: block;
  }
}
.opc{
  &-estimated-wrapper{
    display: none;
  }
  &-progress-bar {
    padding: initial {
      bottom: 5rem;
      top: 5rem;
    };
    width: 100%;
    @include flexAlign(space-between, null, flex);
    gap:.7rem;
    counter-reset: i;
    max-width: calc(100% - 39rem);

    li {
      margin: 0 {
        bottom: 1rem;
      }
      display: inline-block;
      position: relative;
      text-align: center;
      vertical-align: top;
      width: 100%;
      max-width: 100%;

      &:before {
        background: $colorPrimary;
        top: 4rem;
        content: "";
        height: 0.1rem;
        left: 0;
        position: absolute;
        width: 100%;

      }

      &:first-child:before {
        border-radius: 0.6rem 0 0 0.6rem;

      }

      &:last-child:before {
        border-radius: 0 0.6rem 0.6rem 0;
      }

      > span {
        display: block;
        padding-bottom: 4.5rem;
        width: 100%;
        word-wrap: break-word;
        color: $colorPrimary;
        text-align: center;
        font-weight: normal;

        &:after {
          left: 50%;
          position: absolute;
          top: 3rem;
          content: counter(i);
          counter-increment: i;
          background: $colorWhite;

          border-radius: 50%;
          border: 1px solid $colorPrimary;
          height: 2.4rem;
          width: 2.4rem;
          font-size: 1.04rem;
          font-weight: 500;
          color: $colorPrimary;
          background-size: 70%;
          content: counter(i);
          counter-increment: i;
          @include flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          transform: translate(-50%, 0);

        }
      }
      &._active {
        opacity: 1;
        &:before {
          background: $colorSecondary;
        }
        > span {
          color: $colorPrimary;
          font-weight: 500;
          &:after {
            color: $colorSecondary;
            border-color: $colorSecondary;
          }
        }
      }
      &._complete {
        cursor: pointer;
        opacity: 1;
        &:before {
          background: $colorSecondary;
        }
        > span {
          color: $colorPrimary;

          &:after {
            content: "\f058";
            @include fontIcon(2.4, 900);
            border-color: $colorSecondary;
            color: $colorSecondary;
          }
        }
      }
    }
  }
  &-wrapper{
    width: 100%;
    max-width: calc(100% - 39rem);
  }
  &-block {
    &-shipping{
      &-information{

        .action{
          background: transparent;
          &:hover{
            background: transparent;
          }
        }
      }
    }
    &-summary {
      @extend .cart-summary;
      max-width: 100%;
    }
  }
  &-sidebar{
    width: 33rem;
    margin-top: -10rem;
    header{
      display: none;
    }
  }
}
.ship {
  &-via{
    margin-top: 4rem;
  }
}
.items{
  &-in{
    &-cart{
      padding-top: 2rem;
      .title{
        cursor: pointer;
        @include flexAlign(space-between, null, flex);
        border:0;
        padding-bottom: 0;
        &:after{
          content: $iconArrow;
          @include fontIcon(1.5);
          @include transition;
          transform: rotate(180deg);
        }
      }
      &.active{
        .title{
          &:after{
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
.payment{
  &-method{
    padding: 1.7rem 0;
    border-bottom: 1px solid $colorBorder;
    &:last-child{
      border-bottom: 0;
      padding-bottom: 0;
    }
    &-title{

      @include flex;
      input{
        width: auto;
        margin-right: 1rem;
      }
    }
    &-content{
      display: none;
      padding-top: 2rem;
      padding-left: 2.4rem;
      form{

      }
    }
    &._active{
      .payment{
        &-method{
          &-content{
            display: block;
          }
        }
      }
    }
  }
  &-group{
    background: $colorGrey;
    padding: 2rem 2.3rem;
  }
  &s{
    .legend{
      @extend .step-title;
      border:0;
    }
  }
  &-option {
    border-bottom: 1px solid $colorGrey;
    padding-bottom: 1.5rem;
    &-content{
      .messages{
        max-width: 100%;
        .message{
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }
    &-title {

      margin: 1.5rem 0 0;

      .action{
        padding: 1rem 0;
        color: $colorPrimary;
        text-align: left;
        border:0;
        font-weight: bold;
        line-height: 1;
        font-size: 2rem;
        display: block;
      }
    }
  }
}
.order{
  &-comment, &-comment-additional{
    margin-bottom: 1rem;
    &-title{
      font-weight: bold;
    }
    textarea{
      resize: none;
    }
    &._collapsible{
      margin-bottom: 1rem;
      @include border($position: bottom);
      @include transition;
      .order-comment-title{
        @include flexAlign(space-between, center , flex);
        &:after{
          @include fontIcon(1.4);
          content: $iconArrow;
          transform: rotate(180deg);
          @include transition;
        }
      }
      &._active{
        border-bottom: 1px solid transparent;
        .order-comment-title {
          &:after {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}


.amazon-button-container__cell{
  margin-top: 2rem;
}

/*** RESPONSIVE ***/

@include breakpoint(size1024px) {

  .opc{
    &-progress-bar, &-wrapper, &-sidebar{
      width: 100%;
      max-width: 100%;
    }
    &-sidebar{
      margin-bottom: 0;
      margin-top: 3rem;
      .modal-inner-wrap{
        max-width: 100%;
      }
    }
    &-block{
      &-summary, &-shipping-information{
        /*max-width: calc((100% - 1.5rem) / 2 );*/
        width: 100%;
        display: inline-block;
        vertical-align: top;

      }
      &-summary{
        margin-right: 1.5rem;

      }
    }
    &-progress-bar{
      padding-top: 0;
    }
  }
  #checkout-shipping-method-load table tbody tr {
    @include flex;
  }
}/* END 1024px */
@include breakpoint(size768px) {
  .opc{
    &-block{
      &-summary, &-shipping-information{
        max-width: 100%;
      }
      &-summary{
        margin-right: 0;
      }
    }
  }
}/* END 768px */
@include breakpoint(size480px) {
  .opc {
    &-block {
      &-shipping {
        &-information {
          .action {
            width: auto;
          }
        }
      }
    }
    &-progress-bar{
      @include flex($wrap: true);
    }
  }
}/* END 480px */