/**** Prices *****/
.price{
  /* Product List Price */
  .product-item-details &{
    &-details{
      font-size: 1.2rem;
      font-style: italic;
      color: $colorGrey;
      padding-left: 1rem;
      margin-top: 1rem;
      display: none;
    }
    &-box{
      margin-right: 0;
      @include flexAlign(center, center, flex);
      @include flexDirection(row-reverse);
      gap: .3rem;
    }
  }
  &-label {
    margin-right: 5px;
  }
  &-box{
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    .special-price {
      .price{
        color: $colorRed !important;
      }
    }
    .old-price{
      .price{
        @include fontDefault(1.7);
        @include fontAdditional($weight: normal, $style: line-through);
      }
    }
  }
  &-filling-amount, &-details{
    order:1;
  }
  &-info-sku{
    font-size: 1.3rem;
  }
  &-filling-amount{
    font-size:1.1rem;
    margin: 0 0 1rem 0;
    line-height: normal;
  }
  &-details{
    font-size: 1.1rem;
    line-height: normal;
    font-style: italic;
    a{
      @include border(null, solid, $colorPrimary, bottom);
    }
  }
  &-per-amount{
    font-size: 1.1rem;
  }
  &-amount-wrap{
    .price-final_price .price{
      font-size: 3.7rem;
      line-height: 4.5rem;
    }
  }
  &-final_price {
    color: $colorPrimary;
    &.tax{
      @include flex;
      justify-content: center;
      align-items: baseline;
    }

    #product-unit-price, #final-amount-text, &.price-box > span{
      display: block;
      @include fontDefault(1.4, normal);
      font-weight: 500;
    }
    .product-info-price &{
      &.price-box {
        > span {
          padding-left: 0;
        }
        .normal-price + .old-price{
          margin-right: 2rem;
        }
      }
    }
    .price, #mwst-dot{
      font-weight: 600;
      @include fontDefault(1.8, 2.5rem);
      display: inline-block;
    }
    #mwst-dot{
      @include fontDefault(2.4, 2.9rem);
    }
  }

  @include breakpoint(size768px){
    &-amount-wrap{
      .price-final_price{
        justify-content: center;
      }
    }
  }
  @include breakpoint(size480px){
    .product-item-details &-box{
      @include flexDirection(column);
      gap: 0;
      margin-bottom: 1.5rem;
    }
  }/* END 480px */
}
