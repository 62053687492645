.webcrossing-widget-teaser-text{
  main &{
    @include outerContent(nopadding);
    margin:{
      top: 3rem;
    };
  }
  main &, .footer-top &{
    .block{
      &-parent{
        @include flexAlign(flex-start, center, flex);
        background: no-repeat center center;
        background-size: cover;
        padding: 4.4rem 4rem;
        position: relative;
        &:after{
           content: "";
           background: rgba($colorPrimary, .2);
           @include overlay;
           z-index: 0;
        }
        > * {
          position: relative;
          z-index: 1;
        }
      }
      &-title{
        padding-top: 0;
        text-align: center;
        margin-right: 5rem;
        padding: 2rem {
          left:0;
          right: 5rem;
        }
        @include border($color: $colorWhite, $position: right);
        h2{
          @include fontDefault(2.8, 3.4rem);
          @include fontAdditional($colorWhite);
          padding-bottom: 0;
        }
      } /* .block-title */
      &-content{
        width: 100%;
        @include flexAlign(space-between, center, flex);
      }/* .block-title */
    }
    .description{
      text-align: center;
      @include fontDefault(2);
      @include fontAdditional($colorWhite);
      padding-right: 10rem;
    }
  }
  &.newsletter{
    background: $colorSecondary;
    .block{
      &-parent{
        @include outerContent;
        &:after{
          display: none;
        }
      }
      &-title{
        flex-shrink: 0;
        border:0;
        margin-bottom: 0;
        padding: {
          top:0;
          bottom: 0;
        };
        h2{
          @include flex($wrap: false);
          width: 100%;
          flex-shrink: 0;
          &:before{
            content: $iconLetter;
            @include fontIcon(3);
            color: $colorWhite;
            display: inline-block;
            margin-right: 2.2rem;
          }
        }
      }
    }
    .description{
      text-align: left;
      @include fontDefault(1.6);
      &-additional{
        width: 100%;
        .title{
          display: none;
        }
        .newsletter{
          margin-top: 0;
          width: 100%;
        }
        .control{
          margin: 0;
        }
        .content{
          width: 100%;
        }
        label{
          display: block;
        }
        input{
          border:0;
        }
        .subscribe{
          @include flexAlign(center, center, flex);
          letter-spacing: 1.6px;
          .label{
            display: none;
          }
        }
      }
    }
  }
  .teaser{
    background: $colorSecondary;
    color: $colorWhite;
    border-color: $colorSecondary;
  }
  .footer-content > div:not(.footer-top) &{
    .block{
      &-title{
        padding: 0;
        h2{
          text-align: left;
          padding: 0;
        }
      } /* .block-title */
    }
  }
  &.flowing-text{
    @include outerContent;
    .block{
      &-title{
        h2{
          color: $colorPrimary !important;
        }
      }
    }
    .description-additional{
      @include flexAlign(space-between, flex-start, flex);
      @include fontAdditional($colorPrimary);
      gap: 3rem;
      h4{
        @include fontDefault(1.6, 2.4rem);
        @include fontAdditional($weight: bold);
        padding-bottom: 0;
      }
      p{
        @include fontDefault(1.6, 2.4rem);
        @include fontAdditional($colorPrimary);
      }
    }
  }
  &.social-media{
    .block{
      &-parent{
        text-align: left;
      } /* .block-parent */
      &-content{
        @include flex(space-between, flex-start, $wrap:true);
      } /* .block-content */
    }
    .description{
      @include fontAdditional($colorWhite);
      max-width: calc(100% - 24rem);
      &-additional{
        max-width:33%;
        ul{
          @include flexAlign(space-between, center, flex);
          gap: 1.5rem;
        }
      }
    }
    .action {
      background: transparent;
      border-color: $colorWhite;
      width: calc(50% - 3rem);
      &:hover{
        background: $colorSecondary;
        color: $colorWhite;
        border-color:$colorSecondary;
      }
      &s {
        width: 100%;
        margin-top: 4rem;
        @include flexAlign(space-between, flex-start, flex);
        gap: 5rem;
      }
    }
  }
  &.service{
    padding: 0;
    margin-top: 0;
    .block{
      &-parent{
        &:after{
          display: none;
        }
        padding: 0 {
          bottom:2rem;
        };
        @include border(1px, $colorSwatchGrey, $position: bottom);
      }
    }
    .description-additional{
      @include flexAlign(space-between, flex-start, flex);
      @include fontDefault(1.6, 2.6rem);
      gap: 3rem;
      ul{
        flex-shrink: 0;
        li{
          @include flexAlign(flex-start, center, flex);
          &:before{
            content: url($iconDir + "check.svg");
            margin-right: 1rem;
            line-height: 0;
          }
        }
      }
      div{
        @include fontDefault(1.5, 2.6rem);
        @include fontAdditional($colorLightGrey);
      }
    }
  }
  .action {
    @include fontAdditional($transform: uppercase);
    &s {
      flex-shrink: 0;
    }
  }
  @include breakpoint(size1200px){
    &.social-media {
      .description {
        max-width: calc(100% - 16rem);
      }
    }
  }
  @include breakpoint(size1024px){
    &.social-media{
      .action {
        width: 100%;
        margin-left: 0;
        &+ .action{
          margin-top: 1rem;
        }
        &s {
          @include flex($wrap: true);
          gap: 0;
        }
      }
      .description {
        max-width: calc(100% - 13rem);
      }
    }
    &.newsletter {
      .block-title {
        margin-right: 0;
        padding-right: 2rem;
      }
      .description{
        padding-right: 2rem;
      }
    }
    &.service{
      .description-additional{
        @include flex($wrap:true);
      }
    }
  }
  @include breakpoint(size768px){
    main &, &.newsletter{
      .block {
        &-title{
          padding: 0;
          border:0;
          width: 100%;
          margin: 0 {
            bottom: 1.5rem;
          };
          &:after{
            width: 80%;
            max-width: 15rem;
            height: 2px;
            content: "";
            background: $colorWhite;
          }
          h2{
            @include flexAlign(center, center);
            padding: 0 {
              bottom: 1.5rem;
            };
          }
        }
        &-content{
          @include flex(center, $wrap:true);

        }
      }
      .description{
        padding-right: 0;
        width: 100%;
        margin-bottom: 1.3rem;
        text-align: center;
      }
    }
    &.newsletter{
      .block {
        &-title {
          &:after{
            display: none;
          }
          h2 {
            &:after{
              display: none;
            }
          }
        }
      }
    }
    .block {
      &-parent {
        @include flex($wrap:true);
      }
    }
    &.social-media{
      .description {
        max-width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
  @include breakpoint(size480px){
    &.service{
      .description-additional{
        @include flex($wrap:true);
      }
    }
    &.newsletter .block{
      &-title h2{
        @include flex($wrap:true);
        &:before{
          width: 100%;
          margin-right: 0;
        }
      }
      &-content .description-additional .subscribe {
        @include flex($wrap:true);
        width: 100%;
        .actions {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }
    &.social-media{
      .description{
        max-width: 100%;
        &-additional{
          width: 100%;
          ul{
            padding-top: 3rem;
            @include flexAlign(center, center);
            gap: 3rem
          }
          max-width: 100%;
        }
      }
    }
    &.flowing-text{
      .description-additional{
        gap:0;
        @include flexDirection(column);
      }
    }
  }
}