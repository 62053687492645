/**** Table *****/
/* Table Simple */
table {
  border-spacing: 0 !important;
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      @include fontTable;
      line-height: 1;
      font-weight: bold;
      text-align: center;
      &:first-child {
        text-align: left;
      }
    }
  }
  tfoot{
    width: 100%;
    display: table;
    background: $colorGrey;
    margin-top: 5rem;
    th{
      @include fontTable;
    }
    tr{
      width: 100%;
      border-bottom: 1px solid $colorWhite;
    }
    .amount{
      text-align: right;
    }
    .mark{
      font-weight: 600;
      color: $colorPrimary;
      padding: 1rem;
      text-align: left;
    }
  }
  th {
    padding: 1rem 0;
    text-align: left;
    @include fontTable(2);
    @include fontAdditional($colorPrimary, 600);
    &.subtotal {
      text-align: right;
    }
    &.price{
      text-align: left;
    }
    &.col{
      padding: 1rem;
      white-space: nowrap;
      @include border(1px, $position: bottom);
      &.action {
        padding: 0;
        width: 100%;
        border:0;
        border-radius: 0;
      }
    }
    &.item {
      text-align: left;
    }
  }
  tbody{
    width: 100%;
    padding: .6rem 0;
    border-bottom: 0.1rem solid rgba($colorBorder, .2);
  }
  .control {
    &.size {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding-right: 8.2rem;
    }
  }
  td {
    padding: 0;
    text-align: left;
    @include fontTable;
    &.amount{
      padding: 1rem 0;
    }
    &.col {
      position: relative;
      text-align: left;
      padding: 1rem 1rem 1.1rem;
      display: table-cell;
      vertical-align: middle;
      color: $colorPrimary;
      .cart{
        &-price{
          font-weight: normal;
        }
      }
      .product {
        &-notice-img{
          margin-right: 1rem;
          max-width: 3rem;
          line-height: 0;
        }
        &-item {
          &-notice{
            padding: 1rem 0;
            font-size: 1.4rem;
            line-height: 3.2rem;
            p{
              font-size: 1.4rem;
              line-height: 3.2rem;
            }
          }
          &-details {
            display: table-cell;
            padding-left: 3rem;
            vertical-align: middle;
            text-align: left;
            >span{
              display: block;
            }
          }
          &-brand {
            width: 100%;
            display: block;
            @include fontTable;
            text-transform: uppercase;
          }
        }
        strong {
          line-height: 1;
        }
      }
      &.action{
        padding: 0;
        border:0;
        display: table-cell;
        &:hover{
          background: transparent;
          color: initial;
        }
        .action{
          text-align: center;
          &-delete{
            &:before{
              display: inline-block;
            }
          }
          span{
            height: auto;
            position: inherit;
            overflow: initial;
            padding-top: .3rem;
            display: inline-block;
            width: 100%;
            @include fontTable;
            font-weight: 300;
            color: $colorPrimary;
          }
          &s-toolbar{
            justify-content: flex-end;
          }
        }
      }
    }
    &.item {
      padding-left: 0;
      display: block;
      width: 100%;
      @include flex;
      a {
        display: table-cell;
        width: auto;
        &.product-item-photo{
          max-width: 33%;
        }
        span {
          position: relative;
          display: block;
          img {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
    &.price {
      text-align: center;
    }
    &.qty {
      .qty{
        text-align: center;
        background: transparent;
        margin: 0;
        input{
          text-align: center;
        }
      }
      label{
        display: inline-block;
        position: relative;
/*
        &:after{
          @include overlay(0,1rem,auto, 100%, $right: true);
          content: $iconArrow;
          @include fontIcon(1.2);
          @include flex(centery);
          transform: rotate(180deg);
        }*/
      }
      .label {
        display: none;
      }
      input {
        display: inline-block;
        @include fontAdditional(normal, $colorPrimary);
        cursor: pointer;
        padding: 1.2rem .9rem;
        width: 5rem;
        border: 1px solid $colorBorder;
      }
    }
    &.subtotal {
      text-align: right;
      .cart{
        &-price{
          font-weight: 600;

        }
      }
    }
  }
  .item {
    &-options {
      margin-top: 1rem;
      @include fontTable;
      line-height: 2rem;
      @include flex($wrap: true);
      @include fontDefault(1.6);
      dl {
        width: 100%;
        overflow: hidden;
        padding: 0;
        margin: 0
      }
      dt {
        float: left;
        width: 50%;
        /* adjust the width; make sure the total of both is 100% */
        padding: 0;
        font-weight: 600;
        margin: 0
      }
      dd {
        float: left;
        width: 50%;
        /* adjust the width; make sure the total of both is 100% */
        padding: 0;
        margin: 0;
      }
    }
    &-actions {
      position: relative;
      td {
        padding: 0;
        text-align: right;
        position: relative;
        .actions-toolbar {
          @include overlay(0,0, 100%, auto, $right: true, $bottom: true);
          @include flexAlign(flex-end, center, flex);
          padding-bottom: 2rem;
        }
      }
    }
  }
  /* Table Account */
  .account &{
    .table-caption {
      display: none;
    }
    td{
      padding: .7rem 1rem;
      @include fontTable;
      &.actions {
        .delete{
          margin-left: 1.5rem;
        }
      }
      &.actions{
        @include flexAlign(flex-start, center, flex);
      }
      &.qty{
        .item{
          @include flex;
        }
        .title{
          @include fontTable;
          padding: {
            bottom: 0;
            right: .7rem;
          }
          @include fontAdditional($weight: normal)
        }
      }
    }
    th{
      @include fontTable;
    }
    &.order-items {
      margin-bottom: 1.5rem;
      tr {
        @include flexAlign(space-between, null, flex);
        td, th {
          display: block;
          &.name {
            width: 40%;
          }
          &.sku {
            width: 10%;
          }
          &.price {
            width: 15%;
            padding-left: 4rem;
          }
          &.qty {
            width: 30%;
            padding: .7rem 1rem;
            ul.items-qty {
              @include flex;
              .item {
                padding-right: 1rem;
                @include flexAlign(center, center);
                .title{
                  @include fontDefault($fontTable);
                  @include fontAdditional($weight: 500);
                  padding: 0;
                  border:0;
                  margin-right: 1.2rem;
                }
              }
            }
          }
          &.subtotal {
            text-align: right;
            width: 15%;
          }
        }
      }
      tfoot {
        margin-top: 2rem;
        display: block;
      }
    }
  }
  .cart &{
    &.items th.col{
      padding-top: 0;
      &.price, &.qty{
        text-align: center;
      }
    }
  }

  &.items{
    th{
      &.col{
        background: transparent;
        @include border($color:rgba($colorPrimary, .17), $position: bottom);
        @include fontAdditional($colorPrimary, 600);
      }
    }
  }
  .opc-block-summary &, .cart-totals &{
    @extend .totals;
    width: 100%;
    th, td {
      line-height: 1;
      font-size: 1.6rem;
      font-weight: normal;
      text-align: left;
      padding: 0 0 1.6rem 0;
    }
    tr{
      &.shipping{
        display: table-row;
      }
      td{
        text-align: right;

      }
    }
  }
  .step-content &{
    td{
      &.col{
        width: 100%;
        text-align: left;
        color: $colorPlaceholder;
        padding: 0 {
          left: 1.5rem;
        };
        &-method{
          &:first-child{
            width:auto;
            padding: 0;
            @include flexAlign(center, center, flex);
          }
        }
        input{
          width: auto;
          color: $colorPlaceholder;
        }
      }
    }
  }
}
.additional-addresses, .orders-recent{
  width: 100%;
  overflow: auto;
}
/* Table .inner-container */
.inner-container{
  table{
    margin-top: 2.5rem;
    thead {
      th {
        text-align: left;
        font-weight: 500;
        border-bottom: 1px solid $colorBorder;
        padding: .7rem 0;
      }
    }
    tbody{
      border-bottom: 0;
      td{
        text-align: left;
        border-bottom: 1px solid $colorBorder;
        padding: .7rem 0;
      }
    }
  }
}

/*** RESPONSIVE ***/
@include breakpoint(size1200px) {
  table .item-options {
    dt, dd {
      width: 100%;
    }
    dt{
      margin-bottom: 0;
    }
    dd{
      margin-bottom: 1.5rem;
    }
  }
}
@include breakpoint(size1024px) {
  table{
    @include flex($wrap: true);
    tr{
      @include flex($wrap: true);
      &.item-info{
        @include flex(space-between);
      }
      th {
        display: none;
      }
      td{
        text-align: left;
        &.amount{
          width: 100%;
          @include flex(flex-start, center);
          &[data-th] {
            &:before {
              content: attr(data-th) ":";
              width: auto;
              text-align: left;
              padding: 0 {
                right: 1rem;
              }
              @include fontTable;
              font-weight: 600;
              display: block;
              flex-shrink: 0;

            }
          }
          span{
            &.price{
              justify-content: flex-start;
            }
            width: 100%;
            @include flexAlign(flex-start, center, flex);
            &[data-th] {
              &:before {
                flex-shrink: 0;
                content: attr(data-th) ":";
                width: auto;
                padding: 0 {
                  right: 1rem;
                }
                text-align: left;
                @include fontTable;
                font-weight: 600;
                display: block;
              }
            }
          }
        }
        &.col[data-th] {
          &:before {
            @include fontDefault($fontTable, $lineHeightTable);
            @include fontAdditional;
            content: attr(data-th) ":";
            width: 100%;
            padding: 0;
            text-align: left;
            font-weight: bold;
            display: block;
            margin-bottom: 1rem;
          }
        }
        &.col{
          width: auto;
          text-align: left;
          padding: 0 {
            right: 1rem;
            bottom: 2rem;
          }
          &.item{
            &:before{
              display: none;
            }
          }
          position: inherit;
          > * {
            display: block;
          }
          &.name{
            padding-bottom: 0;
          }
          .actions-toolbar{
            position: absolute;
            padding: 0;
            top: 4rem;
            right: 0;
            margin-right: 0;
            margin-top: 0;
            @include flexAlign(center, center, flex);
            a{
              display: inline-block;
            }
          }
          &.action{
            top: 4rem;
          }
        }
        &.subtotal {
          padding-right: 0;
        }
        &.item {
          width: 100%;
          padding-right: 0;
          padding-top: 0;

          > a {
            width: auto;
            display: inline-block;

            &.product-item-photo{
              max-width: 100%;
            }
          }
          .product {
            &-item {
              &-photo{
                min-height: inherit;
              }
              &-details {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 8rem);
              }
            }
          }
        }
        &.qty{
          padding-top: 0
        }
      }
    }
    td{
      &.qty{
        .qty{
          text-align: left;
        }
      }
    }
    thead {
      width: 100%;
      display: none;
    }
    tbody{
      &.cart.item{
        position: relative;
        padding: 0 0 2rem;
        margin-bottom: 2rem;
        td .actions-toolbar{
          padding-bottom: 0;

        }
      }
    }
    .account &{
      tbody{
        border:0;
        padding: 0;
      }
      tr{
        padding-top: 0;
        @include border(.1rem, $position: bottom);
        margin-bottom: 2rem;
        &:last-child{
          border:0;
          margin-bottom: 0;
        }
        td.col {
          width: 100%;
          @include flex($wrap:true);
          &[data-th]::before {
            width: auto;
            margin-right: 1rem;
          }
          .price {
            margin-top: 0;
          }
          a {
            margin-top: 0;
          }
          &.price{
            padding: 0 {
              bottom: 2rem;
            }
          }
          &.actions{
            a{
              display: inline-block;
              width: auto;
              padding: 0;
              font-weight: 600;
              color: $colorSecondary;
              letter-spacing: 0;
            }
          }
          .item-options{
            margin-top: 0;
            @include flex;
            width: 100%;
            dd{
              width: auto;
              margin-left: .5rem;
              margin-right: 2rem;
            }
            dt{
              width: auto;
            }
          }
        }
      }
      tfoot{
        padding: 0 1rem;
        tr{
          margin-bottom: 0;
          td{
            padding: 2rem 1rem;
            span.price, strong{
              justify-content: flex-end;
              margin-left: auto;
            }
          }
        }

      }
    }
    &.table {
      &-order-items {
        tr td {
          strong {
            color: $colorSecondary;
          }

          &.price {
            padding: .7rem 1rem;
          }
        }
      }
      &-additional-addresses-items{
        tr{
          padding-bottom: 2rem;
          border-bottom: 1px solid $colorBorder;
          margin-bottom: 2rem;
          &:last-child{
            border:0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    &.cart{
      tr td.col[data-th]::before{
        /*text-transform: uppercase;*/
      }
    }
    .cart-totals &, .opc-block-summary &{
      th {
        display: inline-block;
        width: 50%;
      }
      tr{
        @include flexAlign(space-between, center, flex);
      }
      td{
        &.amount {
          width: 50%;
          &[data-th]::before {
            display: none;
          }
          strong{
            width: 100%;
          }
          span {
            &.price{
              @include flexAlign(flex-end, null);
            }
            &[data-th]::before {
              display: none;
            }
          }

        }
      }
    }
  }
  .inner-container{
    table{
      thead{
        display: none;
      }
      tbody{
        border-bottom: 0;
      }
      tr{
        padding-top: 2rem;
      }
      td{
        &.col{
          padding-left: 0;
          padding-bottom: 1rem;
          &[data-th]::before{
            padding-bottom: 0;
          }
        }
      }
    }
  }
}/* END 1024px */

@include breakpoint(size480px){
  table {
    tr {
      &.item-actions{
        td{
          .actions-toolbar{
            @include flex(centery, $wrap:false);
            .action{
              padding: 0;
            }
            .action + .action{
              margin-top: 0;
              padding-left: 1rem;
            }
          }
        }
      }
      td {
        &.col {
          width: 100%;
          @include flex(flex-start, center);
          padding-bottom: 1.5rem;
          &[data-th]::before{
            width: auto;
            margin-bottom: 0;
            margin-right: 1rem;
          }
        }
        &.item{
          padding-bottom: 0;
          .product-item-details{
            width: 100%;
            padding-left: 0;
            padding-top: 2rem;
          }
        }
        &.qty{
          .qty input{
            padding: {
              top: 0;
              bottom:0;
            };
          }
        }
      }
    }
    tbody.cart.item td .actions-toolbar{
      position: relative;
      a{
        padding: 1rem;
        @include border(1px, $colorPrimary);
        margin-right: 1rem;
      }
    }
  }
}/* END 480px */
@include breakpoint(size360px){
  table tr td.item{
    @include flex($wrap:true);
  }
}



