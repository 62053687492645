#notice-cookie-block{
  @include overlay(0,0,100%, auto, fixed, $bottom: true, $z-index:999);

  max-width: 100%;
  padding: 0;
  .checkout-index-index &{
    max-width: 100%;
  }
  p{
    margin-bottom: 0;
    padding: 1rem 0 {
      right: 1rem;
    }
  }
  p, a{
    @include fontDefault(1.4, 2.6rem, $colorPrimary);
  }
  .content {
    @include flex(space-between, center, $wrap: false);
    @include outerContent;

    border-bottom: 0;
    background: $colorWhite;
    padding-right: 0;
    @include boxShadow(.1, .1, .1, .1, rgba($colorPrimary, .2));
    .actions {
      text-align: right;
      button {
        margin-right: 0;
        min-width: 19rem;
        white-space: nowrap;
        letter-spacing: 0;
        background: $colorSecondary;
        color: $colorWhite;
        @include border($color: $colorSecondary);
      }
    }
    a{
      opacity: .8;
      @include transition;
      text-decoration: underline;
      &:hover{
        opacity: 1;
      }
    }
  }
  @include breakpoint(size768px) {
    padding: 0;
    .content{
      padding-right: 1.5rem;
    }
    p{
      margin:0;
      padding-right: 0;
    }
    .content {
      @include flex($wrap: true);;
      .actions {
        padding-bottom: 1rem;
        text-align: left;
        width: 100%;
      }
    }
  }
}