.box-tocart {
  .fieldset {
    @include flex;
    padding-bottom: 0;
    .input-text.qty {
      @include fontDefault($lineHeight: normal);
      text-align: center;
      text-decoration: none;
      padding: .8rem;
      @include fontAdditional($colorPrimary);
      background: $colorWhite;
      width: auto;
      max-width: 6rem;
      height: 100%;
      @include border;
    }
    .field.qty {
      margin: {
        right: 1rem;
        top: 0;
      }
      width: auto;
      label {
        width: 100%;
        display: block;
        @extend .price-final_price;
      }
      .control {
        height: 100%;
        margin:0;
        .remove, .add {
          cursor: pointer;
          width: 3rem;
          text-decoration: none;
          text-align: center;
          @include fontDefault(2, 1);
          @include fontAdditional($colorPrimary, normal);
          border: 0;
          background: transparent;
          padding: 0;
        }

        .remove {
          content: "-";
        }

        .add {
          content: "+";
        }

      }
    }
    .actions {

    }
  }
}
.product {
  /* Product List Page */
  &-item {
    width: calc((100% - 9rem) / 4);
    margin-bottom: 2.6rem;
    position: relative;
    z-index: 1;
    @include transition;
    &:nth-of-type(4n){
      margin-right: 0;
    }
    &:hover{
      z-index: 2;
      .product-item-swatches{
        > div >{
          .swatch-attribute:last-child{
            opacity: 1;
            z-index: 3;
            max-height: 99rem;
          }
        }
      }
    }
    &-label{
      .text{
        @include fontDefault(1.4, 2.3rem);
        @include fontAdditional($colorWhite, 600);
        background: $colorRed;
        padding: .2rem 1.3rem;
        display: inline-block;
      }
    }
    &-link{
      &.link{
        margin-top: 1rem;
        opacity: 0;
        transform: scale(0);
        > a{
          @include fontDefault(1.7);
          @include fontAdditional($colorPrimary, bold);
        }
        width: 100%;
      }
    }
    &-info{
      z-index: 2;
      text-align: center;
      position: relative;
      > *{
        @include transition;
      }
      .price-details{
        display: none;
      }
      &:hover{
        .product{
          &-item{
            &-overlay{
              opacity: 1;
            }
          }
        }
      }
    }
    &-image{
      position: relative;
      overflow: hidden;
      text-align: center;
    }
    &-photo{
      width: 100%;
      display: inline-block;
      text-align: center;
      transform: translate(0);
      .cart &{
        width: auto;
      }
      span {
        display: inline-block;
        width: 100%;
        max-width: 100%;
      }
      img {
        @include overlay(top, left, null, null);
        vertical-align: top;
      }
    }

    &-details{
      text-align: center;
      color: $colorPrimary;
      transform: translate(0);
      position: relative;
      > *{
        @include transition;
      }
      a{
        color: $colorPrimary;
      }
    }
    &-price{
      margin-top: 1.1rem;
      .callforprice-action{
        width: auto;
        margin-bottom: 0;
        .tocart{
          @include fontDefault(1.5);
          padding: 1.3rem;
        }
      }
      .price-amount-wrap .price-final_price .price{
        @include fontDefault(3, 3.6rem);
      }
      .box-tocart{
        @include flex;
        align-items: center;
      }
    }
    &-brand {
      text-transform: uppercase;
      @include fontDefault(1.5, 1.8rem);
      @include fontAdditional($colorPrimary, 300);
      text-align: center;
    }
    &-name{
      @include fontDefault(1.8, 2.5rem);
      @include fontAdditional($weight: 600);
      margin-top: .5rem;
    }

    &-swatches{
      > div >{
        .swatch-attribute:last-child{
          @include overlay(100%, 0, 100%, auto);
          padding: 1.2rem 1rem;
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          @include transition;

          z-index: 0;
          background: $colorWhite;
        }
      }
    }
    &-wishlist{
      @include overlay(1rem, .8rem, auto, auto, $right: true);
      z-index: 2;
    }
    &-actions{
      width: 100%;
      margin-top: 1.5rem;
    }
    &s{
      @include flex(flex-start, $wrap:true);
      gap: 3rem;
      .webcrossing-widget-product-slider &{
        gap: 0;
      }
    }
    &-inner{
      .details-qty{
        @include fontDefault(1.6);
      }
    }
  }/* End .product-item */
  &-image{
    &-container{
      position: relative;
      display: block;
      width: 100%;
      img{
        @include overlay(top, left, null, null);
      }
    }
    &-photo{
      @include overlay(top, left, null, null);
    }
  }/* End .product-image */
  /* END */
  /* Product Detail Page */
  &-info{
    &-container{
      @include flexAlign(space-between, null, flex);
      position: relative;
      margin-bottom: 7rem;
      gap: 3rem;
    }
    &-media {
      max-width: 58.2rem;
      position: relative;
      width: 100%;
      z-index: 1;
      .action-skip-wrapper{
        display: none;
      }
      .giftcard {
        &-template {
          &-setting{
            display: none;
          }
        }
      }
    }/* End -media */
    &-main {
      width: 100%;
      @include fontDefault(1.6, 2.6rem);
      @include flexDirection(column, flex);
      order:1;
      font-weight: normal;
      position: relative;
      z-index: 1;
      .page-title{
        text-align: left;
        width: 100%;
        @include fontAdditional($weight: 600);
        @media only screen and (max-width: #{$tabletPortrait}) /* 768px */ {
          padding-bottom: 1.5rem;
        }
        span.price-label {
          display: none;
        }
        .swatch-attribute-label {
          @include fontDefault(1, 1);
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.15rem;
          display: block;
          padding-bottom: 1.3rem;
        }
        &-wrapper{
          padding: 0;
          @include flexAlign(space-between, center, flex);
        }
      }
    }/* End -media */
    &-brand{
      &-logo{
        max-width: 10rem;
        height: auto;
        line-height: 0;
      }
    }
    &-short{
      &-description{
        margin-top: .9rem;
        p{
          @include fontDefault(1.9, 2.3rem);
          @include fontAdditional($colorSecondary, 500);
        }
      }
    }
    &-price {
      order:1;
      @include flex($direction: column, $wrap:true);
      .sale-price{
        order: 1;
      }
      .price {
        &-box {
          @include flex(flex-endy, $direction: row-reverse, $wrap:true);
        }
      }
      .normal-price{
        padding-left: 1.5rem;
        .price{
          @include fontDefault(3, 3.3rem);
          @include fontAdditional($colorSecondary, 600);
        }
      }
    }
    &-header{
      @include flex(space-between, center);
      min-height: 10rem;
    }
    &-sizetable{
      @include fontDefault(1.6);
      padding-left: 2.6rem;
      a{
        @include flexAlign(flex-start, center, flex);
        &:before{
          content: url($iconDir + "ruler.svg");
          margin-right: 1rem;
        }
      }
    }
    &-suitability{
      padding-bottom: 3rem;
      .label{
        @include fontDefault(1.6);
        @include fontAdditional(null, 600);
        display: inline-block;
        width: 100%;
      }
      .value{
        width: 100%;
      }
    }
    &-tip{
      @include flexAlign(flex-start, flex-start, flex);
      padding-bottom: 5rem;
      @include border(1px, $colorSwatchGrey, $position: bottom);
      margin-bottom: 2rem;
      .label{
        flex-shrink: 0;
        @include fontAdditional(null, 600);
        padding-top: 2.5rem;
      }
      .value{
        padding: 2rem;
        margin-left: 6rem;
        background: $colorGrey;
        @include fontDefault(1.6, 2.6rem);
        @include fontAdditional($fontStyle: italic );
        position: relative;
        min-width: calc(100% - 15rem);
        min-height: 10rem;
        &:before{
          @include overlay(4.5rem, -4.5rem, 0, 0);
          border-top: 3.5rem solid $colorGrey;
          border-left: 6rem solid transparent;
          content: "";
          transform: rotate(15deg);
          z-index: -1;
        }
      }
    }
  }/* End .product-info */
  &-add {
    &-form {
      .giftcard {
        &-template {
          &-preview {
            max-width: 8rem;
            display: none;
            > div {
              max-width: 100%;
            }
          }
          &-setting {
            padding-bottom: 5rem;
            .block-title {
              margin-bottom: 1.4rem;
            }
            &-select {
              > div {
                @include flex;
              }
            }
          }
        }
        &-design {
          &-button {
            &-container {
              margin-right: 1.2rem;
              max-width: 8rem;
              i {
                line-height: normal;
              }
              img {
                border: 2px solid $colorWhite;
              }
              span {
                width: 100%;
                display: block;
                font-style: italic;
                font-size: 1rem;
                margin-top: 0.5rem;
                line-height: normal;
                border: 1px solid #fff;
              }
              button {
                border: 0;
                background: transparent;
                @include fontDefault;
                @include fontAdditional;
              }
              &.active {
                img {
                  border: 2px solid $colorPrimary;
                }
              }
            }
          }
        }
      }
    }
    /* End -add-form */
  }/* End .product-add */
  &.attribute.description {
    @include fontDefault(1.6, 2rem);
    padding-bottom: 3rem;
  }
  &-options{
    &-bottom{
      margin-top: 3rem;
      padding-bottom: 2.5rem;
      margin-bottom: 2.5rem;
      @include border(1px, $colorSwatchGrey, $position: bottom);
      @include flexAlign(flex-start, center, flex);
    }
  }
  &.detailed {
    @include flex;
    width: 100%;
    padding: 0 {
      bottom: 4.5rem;
    };
    .item {
      &s{
        position: relative;
        width: 100%;
      }
      &.title {
        float: left;
        width: auto;
        padding-bottom: 0;
        @include fontDefault(1.8);
        a {
          display: block;
          z-index: 2;
          cursor: pointer;
          @include fontAdditional($weight: normal);
          padding: 1.5rem 3rem;
          text-align: center;
        }
        &.active {
          a {
            background: $colorGrey;
          }
          &+.content .inner-content {
            opacity: 1;
          }
        }
        &.active + .content{
          display: block;
        }
      }
      &.content {
        float: right;
        margin-left: -100%;
        width: 100%;
        padding: 4.2rem 3.5rem;
        @include border(1px, $colorSwatchGrey);
        margin-top: 5rem;
        background: $colorWhite;
        display: none;
        &.active {
          display: block;
        }
      }
    }
    .inner {
      ul{
        padding-left: 1.5rem;
        li{
          list-style: disc;
        }
      }
      &-content {
        opacity: 0;
        margin: 0 auto;
        @include fontDefault(1.6, 2.6rem);
        .inner:not(:last-child) {
          padding-bottom: 2.5rem;
        }
        &.specifications{
          ul{
            padding-left:0;
          }
          li{
            list-style: none;
            margin-bottom: 1.8rem;
            .label{
              font-weight: bold;
              @include fontDefault(1.8);
            }
          }
        }
      }
    }
  }
  /* END */
  &s{
    &-grid{
      width: 100%;
      .box-tocart .fieldset{
        width: 100%;
        @include flex(center, $wrap:true);
        .field.qty .control{
          height: auto;
        }
      }
    }
  }

  &-related, &-upsell {
    &-products {
      order:3;
      margin: 0 {
        bottom: 7rem;
      }
      .box-tocart .fieldset .field.qty{
        margin-right: 2rem;
      }
      .title{
        @include fontDefault(4.7, 5.6rem);
        @include fontAdditional(initial, 700);
        text-transform: uppercase;
        margin-bottom: 4rem;
        text-align: center;
      }
      .product{
        &-item{
          width: calc((100% - 6rem) / 3);
          &-price{
            padding-top: 4rem;
          }
        }
      }
      .price {
        &-details {
          display: none;
        }
      }
    }
  }
}
.filling_amount{
  padding: 1.2rem 0;
  font-size: 1.6rem;
  color: $colorGrey;
}

/*** RESPONSIVE ***/

@include breakpoint(size1200px){
  .product {
    &-item{
      width: calc((100% - 6rem) / 3);
      &-price{
        @include flex(centery, $wrap:true);
        .box-tocart{
          width: 100%;
          margin-top: 2rem;
        }
      }
      &-actions{
        width: 100%;
      }
    }
  }
}/* END 1200px */
@include breakpoint(size1024px){
  .product {
    &-item{
      width: calc((100% - 3rem) / 2);
    }
    &-info{
      &-sizetable{
        padding-left: 0;
      }
      &-main{
        padding-left: 0;
      }
      &-container{
        margin-top: 5rem;
      }
    }
    &-related, &-upsell {
      &-products {
        .product-item{
          width: calc((100% - 3rem) / 2);
        }
      }
    }
  }
}/* END 1024px */
@include breakpoint(size768px){
  .product{
    &-info{
      &-container{
        @include flex($wrap:true);
      }
      &-media{
        max-width: 100%;
      }
    }
    &-related, &-upsell {
      &-products {
        .title{
          @include fontDefault(3, 3.9rem);
        }
      }
    }
    &-item{
      &-swatches{

        .size{
          @include overlay(inherit, inherit, inherit, inherit, $position: relative);
          opacity: 1;
          max-height: initial;
        }
      }
      &-details{
        a{
          color: $colorWhite;
        }
      }
      &-name{
        a{
          color: $colorPrimary;
        }
      }
    }
    &.detailed{
      .item{
        &.title{
          width: 100%;
          float: none;
          @include border(0);
          &.active{
            @include border(0);
            a{
              opacity: 1;
              color: $colorDarkGrey2;
            }
          }
          a{
            @include border(1px, $colorSwatchGrey);
          }
        }
        &.content{
          margin: {
            top: 0;
            left: 0;
          }
          float: none;
        }
      }
    }
  }
}/* END 768px */
@include breakpoint(size480px){
  .box-tocart {
    width: 100%;
    padding-right: 1rem;
    .fieldset {
      width: 100%;
      .field{
        &.qty{
          margin: {
            top: 0;
          }
        }
      }
      .actions {
        width: 100%;
        margin-left: 0;
        padding: {
          left: 1rem;
          right: 1rem;
        }

        button {
          width: 100%;
        }
      }
    }
  }
  .product{
    &-info{
      &-header{
        @include flex(null, flex-start, $direction: column-reverse);
        min-height: initial;
      }
      &-sizetable{
        width: 100%;
        padding: {
          left: 0;
          top: 2rem;
        }
      }
      &-main{
        padding-bottom: 0;
      }
      &-container{
        @include flex($wrap:true);
      }
      &-tip{
        @include flex($wrap:true);
        .label{
          width: 100%;
        }
        .value{
          margin-left: 0;
          margin-top: 3rem;
          min-width: 100%;
          &:before{
            top: -1.5rem;
            left: 7.4rem;
            transform: rotate(35deg);
          }
        }
      }
    }
    &-item{
      width: calc((100% - 1rem) / 2);
    }
    &-add-form{
      width: 100%;
    }
    &-related, &-upsell {
      &-products{
        width: 100%;
        .product-item{
          width: 100%;
          margin-right:0;
          &:nth-of-type(3n),  &:nth-of-type(4n){
            margin-right:0;
          }
          &:nth-of-type(2n){
            margin-right: 0;
          }
        }
      }
    }
    &s.list{
      gap: 1rem;
      @include flexAlign(space-between, null);
    }

  }
}/* END 480px */
@include breakpoint(size360px){
  .product-item{
    width: 100%;
  }
} /* END 360 */

