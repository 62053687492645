/*Product Detail*/
.preview-giftcard{
  background-size: 100% !important;
}

.catalog {
  &-product {
    &-view{
      main{
        .page-wrapper {
          padding-top: 0;
        }
      }
      .column{
        margin-top: 3rem;
      }
      .price{
        &-details{
          padding-bottom: 0;
          border:0;
        }
      }
      .product{
        &-item{
          &-info{
            text-align: left;
          }
        }
      }
    }
  }
}