#store.settings{
  display: none;
}
.navigation{
  @include flexAlign(flex-start, stretch, flex);
  &-header, &-footer{
    display: none;
  }
  > ul {
    @include flex(null, stretch);
    width: 100%;

    li {
      width: 100%;
      &:hover {
        > a {
          color: $colorSecondary;
        }
      }
      &.level0 {
        @include flex(null, center);
        > ul{
          display: none;
          @include overlay(100%, left, 100vw, auto);
          z-index: 2;
          background: $colorWhite;
          padding: 3rem 4rem;
          max-width: 66vw;
          gap: 2rem;
        }
        > a {
          padding: 0 2rem;
        }
        &:hover{
          > ul{
            @include flex($wrap: true);
            max-height: calc(100vh - 16.5rem);
            overflow-x: auto;
          }
        }
        &.activities {
          > ul{
            li{
              position: relative;
            }
            &:last-child {
              margin-right: 0;
            }
          }
          &:hover{
            @include flex(space-between, $gap: 3rem);
          }
        }
        &.brands{
          > ul{
            > li{
              width: calc((100% - 10rem ) / 6);
              ul{
                display:none;
              }
              .image {
                img {
                  width:auto;
                }
              }
            }
          }
        }
      }
      &.level1{
        width: calc((100% - 6rem ) / 3);
        &:last-child{
          padding-right: 0;
        }
        a {
          > .image {
            width: 100%;
            line-height: 0;
            img{
              width: 100%;
            }
          }
          > .overlay{
            @include overlay;
            @include flex(centery);
            padding: 0 2rem;
            background: rgba($colorPrimary, .5);
            h2{
              @include fontDefault(2.8, 3.4rem);
              text-align: center;
              @include fontAdditional($colorWhite, 600);
              text-transform: normal;
              @include transition;
              opacity: 1;
            }
          }
        }
      }
      &.menu-cms-blocks {
        width: calc((100% - 6rem ) / 3);
        order:2;
        ul{
          @include flex($direction: column, $gap: 2rem)
        }
        a{
          padding: 0;
          > .image {
            width: 100%;
            line-height: 0;
            img{
              width: 100%;
            }
          }
          > .overlay{
            @include overlay;
            @include flex(centery);
            padding: 0 2rem;
            background: rgba($colorPrimary, .5);
            h2{
              @include fontDefault(2.8, 3.4rem);
              text-align: center;
              @include fontAdditional($colorWhite, 600);
              text-transform: normal;
              @include transition;
              opacity: 1;
            }
          }
        }
      }
      a {
        color: $colorFontDefault;
        padding: 0 2rem 2rem 2rem;
        position: relative;
        @include fontDefault(1.8, normal);
        @include fontAdditional($colorFontDefault, 600, uppercase);
        text-transform: uppercase;
        display: block;
        &.image-container {
          padding:0;
        }
        &:hover{
          h2{
            opacity: 0;
          }
        }
      }
    }
  }

  a,  li{
    word-break: normal;
    hyphens: none;
  }
}
.nav-toggle{
  display: none;
}
.menu-cms-block{
    a{
      position: relative;
    }
}
@include breakpoint(size1500px){
  .navigation {
    ul {
      .overlay {
        h2 {
          @include fontDefault(2, normal);
        }
      }
    }
    > ul li.level0 > ul{
      max-width: 80vw;
    }
  }
}
@include breakpoint(size1200px){
  .navigation ul {


    &.menu {
      &-activities, &-cms-blocks {
        li a .overlay h2 {
          font-size: 2rem;
        }
      }
    }
  }
}
/*** RESPONSIVE ***/
