.webcrossing-widget-product-slider{
  max-width: 100% !important;
  padding: {
    left: 0 !important;
    right: 0 !important;
  };
  .block {
    &-title {
      justify-content: center;
      padding: {
        top: 10rem;
      }
      @include outerContent;
    }
    &-content {
      position: relative;
      .toolbar{
        padding-bottom: 0;
      }
    }
  }
  .splide{
    @include outerContent;
    padding-bottom: 4rem;
    &__pagination__page{
      width: 4.3rem;
      height: .3rem;
      border-radius: 0;
      opacity: .2;
      margin: 0 .3rem;
      background: $colorPrimary;
      &.is-active{
        opacity: .9;
        transform: scale(1);
      }
    }
    &__arrow {
      svg{
        display: none;
      }
      &.splide__arrow {
        height: auto;
        background-color: transparent;
        @include flexAlign(center, center);
        position: absolute;
        border-radius: if($rounded-corners, 0, null);
        &--prev, &--next {
          &:before{
            content: url($iconDir + "slide_right.svg");
          }
        }
        &--prev {
          left: -2rem;
          &:before{
            transform: rotate(180deg);
          }
        }
        &--next {
          right: -2rem;
          &:before{

          }
        }
      }
    }
  }
  .product{
    &-item{
      margin-bottom: 0;
      &-photo{
        transform: translate(0);
      }
      &-details{
        transform: translate(0);
      }
      &-price{

        justify-content: center;
        #product-unit-price{
          display: none;
        }
        .price-details{
          margin-top: 0;
        }
      }
      &:hover{
        background: transparent;
        .product {
          &-item {

            &-photo{
              transform: translate(0);
            }
            &-details{
              transform: translate(0);
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1600px) {
    .block{
      &-content{
        padding: 0 5rem;
      }
    }
    .splide{
      position: inherit;
      &__arrow {
        &.splide__arrow {
          &--prev {
            left: 2rem;
          }
          &--next {
            right: 2rem;
          }
        }
      }
    }
  }
  @include breakpoint(size768px){
    .block-title{
      padding-top: 5rem;
    }
  } /* END 768px */
  .cms-home &{
    .block {
      &-title {
        padding-top: 6rem;
      }
    }
  }
}
