#checkout {
  &-loader {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed !important;
    right: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba($colorWhite, 0.7);
    @include flexAlign(center, center, flex);
    .loader {
      img {
        width: 100%;
        max-width: 6.4rem;
        z-index: 9999;
      }
    }
  }
}
.cart {
  &-totals{
    .table {
      &-wrapper {
        .load.indicator {
          background-color: $colorLightGrey;
          z-index: 9999;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
          &:before {
            background: transparent url($imageDir + "loader.gif") no-repeat 50% 50%;
            border-radius: 0.5rem;
            height: 16rem;
            width: 16rem;
            bottom: 0;
            box-sizing: border-box;
            content: "";
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
          }
          > span {
            display: none;
          }
        }
        body > .loading-mask {
          z-index: 9999;
        }
        ._block-content-loading {
          position: relative;
        }
        [data-role="main-css-loader"] {
          display: none;
        }
      }
    }
  }
  &-summary {
    .load {
      &ing-mask {
        position: absolute !important;
        background: rgba($colorWhite, .9);
        margin: 0;
      }
    }
  }
}
.minicart-wrapper{
  ._block-content-loading {
    .counter-number {
      background: $colorWhite;
    }
  }
  .load {
    &er {
      > img {
        max-width: 100%;
        min-width: initial;
        background: transparent;
        min-height: initial;
      }
    }
    &ing-mask {
      @extend .counter-number;
      position: absolute !important;
      left: initial;
      bottom: initial;
    }
  }
}
/*Loader*/
.load {
  &er {
    width: 100%;
    height: auto;
    p  {
      display: none;
    }
    > img {
      max-width: 6.4rem;
      @include flexAlign(center, center, flex);
      position: absolute;
      min-width: 4.4rem;
      min-height: 4.4rem;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
    }
  }
  &ing-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed !important;
    right: 0;
    top: 0;
    z-index: 9999;
    background: rgba($colorWhite, .7);
  }
  &.indicator {
    background-color: rgba($colorWhite, .7);
    z-index: 9999;

    &:before {
      background: transparent url($imageDir + "loader.gif") no-repeat 50% 50%;
      border-radius: 0.5rem;
      box-sizing: border-box;
      content: "";
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
    }

    > span {
      display: none;
    }
  }
}