.swatch {
  .filter &{
    &-attribute {
      &-options {
        @include flex(flex-start, center, $wrap: true);
        a {
          border-radius: 0;
         @include border(0);
        }
      }
      /*&.color {
        margin-top: 0;
        .swatch-attribute-options {
          a {
            border-radius: 0;
            position: relative;
            overflow: hidden;
            div {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
          }
        }
      }*/
    }
  }
  &-attribute {
    &-selected-option {
      display: inline-block;
      margin-left: .5rem;
    }
    &-label{
      display: inline-block;
    }
    &-options {
      @include flex(center, center, $wrap:true);
      gap: 1rem;
      padding-bottom: 1rem;
      min-height: 5.5rem;
      .product-item-swatches &{


        .swatch-option{
          &.image{
            max-width: calc((100% - 1rem) / 6);
            max-height: 3.3rem;
            background-size: contain !important;
          }
          &.text{
            padding: {

            };
          }
        }
      }
      .product-item-swatches .color &{
        gap: .2rem;
      }

    }
    &:first-child{
      margin-top: 2rem;

    }
    /*&.color{
      order: 0;
      a{
        width: 3rem;
        height: 3rem;
        position: relative;
        div{
          @include overlay(0,0);
        }
        .selected {
          @include border($color: $colorBlack);
        }
      }
    }*/
    &.size{
      @include transition;
      .action{
        width: 100%;
        padding: {
          right: 1rem;
          left: 1rem;
        };
      }
    }
  }
  &-option{
    width: 3.6rem;
    height: 3.6rem;
    @include flexAlign(center, center, flex);
    cursor: pointer;
    position: relative;
    &.text{
      width: auto;
    }
    &.disabled {
      opacity: 0.4;
      @include transition;
      overflow: hidden;
      color: $colorSwatchGrey;
      border-color: $colorSwatchGrey;
      cursor: not-allowed;
      &:before, &:after{
        content: "";
        background: $colorSwatchGrey;
        @include overlay(null, null, 150%, .2rem);
      }
      &:before{
        transform: rotate(-45deg);
      }
      &:after{
        transform: rotate(45deg);
      }
    }
    &.image{
      border:0;
      @include flexAlign(center, center, flex);
      &.disabled{
        cursor: not-allowed;
        filter: grayscale(100%);
        &:after, &:before{
          display: none;
        }
      }
    }
    &.text{
      padding: 1rem;
      @include border(1px);
      @include fontDefault(1.6);
      @include transition;
      &:hover{
        @include border(1px, $color: $colorPrimary);
      }
      &.disabled{
        &:hover{
          @include border;
        }
      }
    }
    &.selected {
      @include border(1px, $colorWhite);
      @include boxShadow(0,0,0, .2, $colorPrimary);
    }
    &-tooltip {
      @include border;
      color: #949494;
      background: #fff;
      display: none;
      max-height: 100%;
      min-height: 2rem;
      min-width: 2rem;
      padding: .5rem;
      position: absolute;
      text-align: center;
      z-index: 999;
      .corner{
        bottom: 0;
        height: 8px;
        left: 40%;
        position: absolute;
        display: none;
        &:before, &:after{
          border-style: solid;
          content: "";
          font-size: 1px;
          height: 0;
          position: relative;
          width: 0;
        }
        &:before{
          border-color: #adadad transparent transparent transparent;
          border-width: 8px 8.5px 0 8.5px;
          left: 0;
          top: 2px;
        }
        &:after{
          border-color: #fff transparent transparent transparent;
          border-width: 7px 7.5px 0 7.5px;
          left: -15px;
          top: 1px;
        }
      }
      .image{
        display: block;
        min-height: 130px;
        margin: 0 auto;
        min-width: 130px;
      }
      .title{
        color: #949494;
        display: block;
        text-align: center;
        @include font;
        @include fontSmaller;
        font-weight: 600;
        min-width: 100%;
      }
    }
  }
  &-opt {
    width: 100%;
    @include flexDirection(column, flex);
    input.swatch-input{
      display: none;
    }
    .swatch {
      &-option {
        &.image {
          max-width: initial;
          padding: 0;
          img {
            border: 1px solid $colorBorder;
            position: relative;
          }
          .label {
            width: 100%;
            display: block;
            @include fontDefault(1, normal);
            @include fontAdditional($style: italic);
            margin-top: 0.5rem;
            @include border;
          }
          &.selected {
            @include border(1px, $colorWhite);
            @include boxShadow(0,0,0, .2, $colorPrimary);
            img {
              @include border($color: $colorPrimary);
            }
          }
        }
      }
      &-attribute{
        &:first-child{
          margin-top: 2rem;
          padding-bottom: 2rem;
        }
        &.size{
          .swatch-attribute-options{
            margin-bottom: 1rem;
          }
        }

        &-label {
          @include fontAdditional($weight: 600);
          display: inline-block;
          width: auto;
        }
        &-options {
          @include flexAlign(flex-start, center, flex);
          margin-top: 1.2rem;
        }
      }
    }
  }
  &-input{
    display: none;
  }
  &-actions{
    &-container{
      /*max-height: 0;
      overflow: hidden;
      @include transition;*/
    }
  }
  @include breakpoint(size1024px){
    &-opt .swatch-attribute.size .swatch-attribute-options{
      @include flex($wrap:true);
    }
  }
  @include breakpoint(size768px){
      &-attribute-options{
        display: none;
      }
  }
}