.webcrossing-widget-media-text{
  margin-bottom: 6rem;
  @include outerContent(nopadding);
  .block {
    &-title{
      padding: {
        right: 2rem;
        left: 2rem;
      };
    }
    &-content {
      @include flexAlign(space-between, center, flex);

      &.left {
        @include flexDirection(row-reverse);
      }
      &.right {
        @include flexDirection(row);
        .video-container{
          @include flex(flex-end);
        }
      }
    }
  }
  .text-container{
    width: 40%;
    padding: 5rem 10rem;
    h3{
      @include fontDefault(3.4, 3.4rem);
      @include fontAdditional($colorPrimary, bold, uppercase);
      padding-top: 0;
    }
    .description{
      line-height: 3.4rem;
      margin: {
        top: 2rem;
        bottom: 3rem;
      }
    }
    .actions{
      text-transform: uppercase;
    }
    li{
      @include fontDefault(2, 3.4rem);
      @include fontAdditional($weight: normal);
      &:before{
        display: inline-block;
        content: "\f058";
        @include fontIcon(1.6);
        margin-right: 1rem;
        color: $colorSecondary;
      }
    }
  }
  .video-container{
    width: 100%;
    iframe{
      max-width: 100%;
    }
  }
  @include breakpoint(size1200px){
    .text-container{
      padding: 5rem;
    }
  }
  @include breakpoint(size1024px){
    margin-bottom: 0;
    .block-content {
      &.left, &.right{
        @include flexDirection(column-reverse);
        > div{
          width: 100%;
          iframe, img{
            width: 100%;
          }
        }
      }
    }
  }
  @include breakpoint(size768px){
    .text-container{
      padding: 2.7rem 2rem ;
    }
  }
}