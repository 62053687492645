.minicart {
  &-items{
    .product{
      text-align: center;
      position: relative;
      z-index: 1;
      &.options{
        display: none;
      }
      &-item{
        width: 100%;
        padding: 0 {
          bottom: 2rem;
        };
        @include border(1px, $position: bottom);
        .product{
          @include flexAlign(space-between, flex-start, flex);
          gap: 2rem;
          text-align: left;
          &.options{
            gap:0;
            @include flexDirection(column);
            @include fontDefault(1.5);
            &.active{
              .toggle{

                &:after{
                  content: "-";
                }
              }
              .content{

              }
            }
          }
          .subtitle{
            display: none;
          }
          .toggle{
            width: 100%;
            cursor: pointer;
            @include flexAlign(space-between, center, flex);

            &:after{
              content: "+";

            }
          }
          .content{
            width: 100%;
            background: $colorGrey;
            padding: .3rem 1rem;
            .label{
              @include fontAdditional($weight: 600);
              margin-top: .5rem;
            }
            .values{

            }

          }
        }
        &-details{
          width: 100%;
          text-align: left;
        }
      }
      &-image{
        &-container{
          display: inline-block;
        }
      }
    }
  }
  &-wrapper {
    text-align: center;
    position: relative;
    .counter {
      @include overlay(-.6rem, -1.2rem, null, null, $right: true);
    }
    > a {
      &:before {
        @include fontIcon;
        content: url($iconDir + "cart.svg");
        width: 100%;
        text-align: center;

      }
      .text {
        display: none;
      }

      .counter {
        &.empty {
          .counter {
            &-number {
              display: none;
            }
          }
        }

        &-number {
          background: $colorWhite;
          display: inline-block;
          color: $colorSecondary;
          @include border($color: $colorSecondary);
          width: 2.2rem;
          height: 2.2rem;
          @include fontDefault(1.2, 2rem);
          text-align: center;
          border-radius: 50px;
          font-weight: 800;
          letter-spacing: -1px;
        }
        &-label {
          display: none;
        }
      }
    }
    a.action{
      @include flex($wrap:true, $direction: row);
      line-height: normal;
    }
  }
}
@include breakpoint(size1200px){
  .minicart-wrapper .counter{
    right: -1rem;
  }
}